import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { ChainName } from 'utils/enums';

export const useTicket = (): any[] => {
  const pathParams = useParams();

  const allProjectTickets = useAppSelector(state => state.projectsSlice.allProjectTickets);
  const { chain } = useAppSelector(state => state.projectsSlice);

  const tickets = useMemo(() => {
    return pathParams.key !== undefined &&
      allProjectTickets[chain === ChainName.apots ? parseInt(pathParams.key) : pathParams.key] !==
        undefined
      ? allProjectTickets[chain === ChainName.apots ? parseInt(pathParams.key) : pathParams.key]
      : [];
  }, [pathParams, allProjectTickets, chain]);

  return tickets;
};
