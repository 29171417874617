import { CloseOutlined } from '@ant-design/icons';
import { useWallet } from '@suiet/wallet-kit';
import { Modal, Switch } from 'antd';
import BigNumber from 'bignumber.js';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import block from 'assets/images/common/block.svg';
import { PageContainer } from 'components/PageContainer';
import { useWinOrExpired } from 'hooks/useWinOrExpired';
import { useAppSelector } from 'store/hooks';
import { getTickets } from 'utils/aptos-sdk';
import { getProjectList } from 'utils/backendApi';
import { AptosWalletName, ChainName, Precision, TokenName } from 'utils/enums';
import { ProjectDetailWithTickets } from 'utils/models';
import { getWalletApi } from 'utils/wallets';

interface ModalStatus {
  isModalOpen: boolean;
  requestStatus?: 'success' | 'pending' | 'fail';
  requestValue?: string;
  info?: string;
}

export const Claim: FC = () => {
  const navigate = useNavigate();
  const wallet = useWallet();

  const { address, connectedWallet } = useAppSelector(state => state.walletStatesSlice);
  const { chain, suiConfig } = useAppSelector(state => state.projectsSlice);
  const winOrExpired = useWinOrExpired();
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [couldClaimOnly, setCouldClaimOnly] = useState(true);
  const [{ isModalOpen, requestStatus, requestValue, info }, setModalStatus] =
    useState<ModalStatus>({
      isModalOpen: false,
    });

  const selectFilter = (filter: string) => {
    let res = [...selectedFilter];
    if (selectedFilter.indexOf(filter) >= 0) {
      res = [];
    } else {
      res = [filter];
    }
    setSelectedFilter(res);
  };

  const claim = async (project: ProjectDetailWithTickets) => {
    if (project === undefined || connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    let requestValue;
    if (project.isWinner) {
      requestValue =
        project.type === 'coin'
          ? `${new BigNumber(project.reward_val).shiftedBy(Precision[chain]).toFormat()} ${
              TokenName[chain]
            }`
          : `${project.reward_val} NFT`;
    } else {
      requestValue = `${new BigNumber(project.ticket_value)
        .shiftedBy(Precision[chain])
        .times(project.ticketAmount)
        .toFormat()} ${TokenName[chain]}`;
    }
    setModalStatus({
      isModalOpen: true,
      requestStatus: 'pending',
      requestValue,
      info: 'Please confirm the transaction from your wallet.',
    });
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.claimOrRefund(parseInt(project.key), project.type);
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'claim_or_fund',
              typeArguments: [project.reward_type as string, project.fund_type],
              arguments: [project.key, suiConfig.objectId.UsersBag],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Claim failure');
        }
      }
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'success',
        requestValue,
        info: 'You have successfully claimed !',
      });
    } catch (err: any) {
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'fail',
        requestValue,
        info: err.toString === undefined ? err : err.toString(),
      });
    }
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  return (
    <PageContainer className="font-inter">
      <div className="flex h-[72px] items-center">
        <div className="text-[24px] font-semibold">Claim</div>
        <div className="ml-[16px] flex items-center gap-[8px] rounded-[8px] bg-gray px-[16px] py-[8px]">
          <div className="text-[15px] font-semibold">Could Claim Only</div>
          <Switch checked={couldClaimOnly} onChange={() => setCouldClaimOnly(!couldClaimOnly)} />
        </div>
        <button
          className={`ml-[24px] rounded-[8px] bg-gray px-[16px] py-[8px] text-[15px] font-semibold duration-300 ${
            selectedFilter.indexOf('win') >= 0 ? 'bg-purple2 text-black' : ''
          }`}
          onClick={() => selectFilter('win')}
        >
          My win
        </button>
        <button
          className={`ml-[8px] rounded-[8px] bg-gray px-[16px] py-[8px] text-[15px] font-semibold duration-300 ${
            selectedFilter.indexOf('expired') >= 0 ? 'bg-purple2 text-black' : ''
          }`}
          onClick={() => selectFilter('expired')}
        >
          Expired
        </button>
      </div>
      <div className="mt-[32px] flex text-[13px] font-bold text-gray2">
        <div className="flex-[2]">Market</div>
        <div className="flex-[1]">Total Return</div>
        <div className="flex-[1]">Status</div>
        <div className="flex-[1]" />
      </div>
      <div className="mt-[24px] mb-[12px] h-[1px] w-full bg-gray" />
      <div className="hide-scrollbar h-[600px]">
        {winOrExpired
          .filter(project => {
            if (selectedFilter.indexOf('win') >= 0) {
              return project.isWinner;
            } else if (selectedFilter.indexOf('expired') >= 0) {
              return !project.isWinner;
            } else {
              return true;
            }
          })
          .filter(project => {
            if (couldClaimOnly) {
              return project?.is_claimed === false && project?.couldRefund === true;
            } else {
              return true;
            }
          }).length > 0 ? (
          winOrExpired
            .filter(project => {
              if (selectedFilter.indexOf('win') >= 0) {
                return project.isWinner;
              } else if (selectedFilter.indexOf('expired') >= 0) {
                return !project.isWinner;
              } else {
                return true;
              }
            })
            .filter(project => {
              if (couldClaimOnly) {
                return project?.is_claimed === false && project?.couldRefund === true;
              } else {
                return true;
              }
            })
            .map((project, i: number) => (
              <div key={i} className="flex h-[104px] items-center duration-300 hover:bg-gray">
                <div className="flex flex-[2] items-center gap-[10px]">
                  <img
                    src={project.url}
                    className="h-[80px] w-[80px] cursor-pointer rounded-[8px]"
                    onClick={() => navigate(`/${chain}/detail/${project.key}`)}
                  />
                  <div
                    className="cursor-pointer text-[15px] font-bold hover:underline hover:underline-offset-4"
                    onClick={() => navigate(`/${chain}/detail/${project.key}`)}
                  >
                    {project.description}
                  </div>
                </div>
                <div className="flex-[1] text-[14px] font-semibold text-gray2">
                  {project.isWinner
                    ? project.type === 'coin'
                      ? `${new BigNumber(project.reward_val)
                          .shiftedBy(Precision[chain])
                          .toFormat()} ${TokenName[chain]}`
                      : `${project.reward_val} NFT`
                    : `${new BigNumber(project.ticket_value)
                        .shiftedBy(Precision[chain])
                        .times(project.ticketAmount)
                        .toFormat()} ${TokenName[chain]}`}
                </div>
                <div className="flex-[1]">
                  <div
                    className={`w-[80px] rounded-[6px] py-[5px] text-center text-[15px] font-semibold ${
                      project.isWinner ? 'linear-border' : 'bg-purple2 text-black'
                    }`}
                  >
                    {project.isWinner ? 'You win' : 'Expired'}
                  </div>
                </div>
                <div className="flex-[1]">
                  <button
                    className={`h-[48px] w-[150px] rounded-[12px] border-[2px] border-white ${
                      project?.is_claimed === true || project?.couldRefund === false
                        ? 'cursor-not-allowed !border-gray2 !bg-gray2'
                        : ''
                    }`}
                    onClick={
                      project.is_claimed === true || project.couldRefund === false
                        ? undefined
                        : () => claim(project)
                    }
                  >
                    {project.is_claimed === true || project.couldRefund === false
                      ? 'Claimed'
                      : project.isWinner
                      ? 'Claim Rewards'
                      : 'Claim Investment'}
                  </button>
                </div>
              </div>
            ))
        ) : (
          <div className="mt-[100px] text-center">
            <img src={block} className="mx-auto animate-bounce" />
            <div className="mt-[50px]">
              <span className="text-[12px] font-medium text-gray2">
                You haven't participated in any activitie yet.
              </span>
              <span
                className="linear-text ml-[10px] cursor-pointer text-[12px] font-semibold underline decoration-gray2 underline-offset-4"
                onClick={() => navigate(`/${chain}/home/#market`)}
              >
                Go to Market
              </span>
            </div>
          </div>
        )}
      </div>
      <Modal
        width={560}
        footer={null}
        open={isModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        maskClosable={requestStatus === 'pending' ? false : true}
        onCancel={() => setModalStatus({ isModalOpen: false })}
      >
        <div className="rounded-[16px] bg-black2 pt-[80px] pb-[50px] text-center">
          <img
            src={
              requestStatus === 'pending'
                ? '/common/pending.svg'
                : requestStatus === 'success'
                ? '/common/check.svg'
                : '/common/fail.svg'
            }
            className={`mx-auto ${requestStatus === 'pending' && 'animate-spin'}`}
          />
          <div className="mt-[20px] text-[20px] font-semibold text-white">
            {requestStatus === 'pending'
              ? 'Wait for Confirmation !'
              : requestStatus === 'success'
              ? 'Succeeded !'
              : 'Failed !'}
          </div>
          <div className="mt-[8px] text-[48px] font-semibold text-white">{requestValue}</div>
          <div className="mt-[16px] text-[15px] font-semibold text-gray2">{info}</div>
        </div>
      </Modal>
    </PageContainer>
  );
};
