import axios from 'axios';
import { store } from 'store';
import { updateProjectList } from 'store/features/projectsSlice';
import { setSuiConfig } from 'store/features/projectsSlice';
import { BACK_END_URL } from './config';
import { OverView, TopWinner, WinnerInfo } from './models';

export const getProjectList = (): Promise<void> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/box/boxView`,
    )
    .then(response => {
      if (response.status === 200) {
        store.dispatch(updateProjectList(response.data.boxes));
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getHomePageData = (): Promise<OverView | void> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/info/homePage`,
    )
    .then(response => {
      if (response.status === 200) {
        return response.data.msg;
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getWinnerList = (): Promise<WinnerInfo[] | void> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/info/WinnersList`,
    )
    .then(response => {
      if (response.status === 200) {
        return response.data.msg;
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getTopWinners = (type: 'APT' | 'NFT'): Promise<TopWinner[] | void> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/info/TopWinners`,
      {
        type,
        num: 10,
      },
    )
    .then(response => {
      if (response.status === 200) {
        return response.data.msg;
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getSelledTickets = (addr: string, creactionNumber?: number): Promise<any> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/info/getSelledTickets`,
      {
        addr,
        creation_number: creactionNumber,
      },
    )
    .then(response => {
      if (response.status === 200) {
        return response.data.msg;
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getConfigList = (): Promise<any> => {
  return axios
    .post(
      `${BACK_END_URL}/${
        window.location.pathname.indexOf('aptos') >= 0 ? 'aptos' : 'sui'
      }/utils/configList`,
    )
    .then(response => {
      if (response.status === 200) {
        store.dispatch(setSuiConfig(response.data.objectList));
      }
    })
    .catch(err => {
      console.log(err);
    });
};
