import { ComponentProps, FC } from 'react';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Menu } from 'components/Menu';

export const PageContainer: FC<ComponentProps<'div'> & { showFooter?: boolean }> = ({
  children,
  className,
  showFooter = false,
}) => {
  return (
    <div className={className}>
      <Header />
      <Menu />
      <div className="mx-[210px] mt-[88px] rounded-[8px] bg-black2 px-[24px] py-[40px]">
        {children}
      </div>
      {showFooter ? <Footer /> : null}
    </div>
  );
};
