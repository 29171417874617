import { JsonRpcProvider } from '@mysten/sui.js';
import axios from 'axios';
import { store } from 'store';
import projectsSlice, { updateTickets } from 'store/features/projectsSlice';
import { SUI_NODE_URL } from 'utils/config';
import { ChainName } from './enums';

const provider = new JsonRpcProvider(SUI_NODE_URL);

export const getCoinsObjectIdReduceGas = async (address: string): Promise<any> => {
  const result: any = await provider.getCoins(address);
  const sortObject: any[] = result.data.sort((a: any, b: any) => b.balance - a.balance);
  if (sortObject[0].balance < 5000) {
    throw new Error('Need merge SUI');
  }
  if (sortObject.reduce((pre, cur) => pre + cur) < 5000) {
    throw new Error("There's not enough gas");
  }
  const objects = sortObject.slice(1);
  return objects.map((v: any) => v.coinObjectId);
};

export const getBalanceSui = async (address: string): Promise<string> => {
  try {
    const result: any = await provider.getBalance(address);
    return result.totalBalance;
  } catch (err: any) {
    console.log(err);
    return '0';
  }
};
