import { CloseOutlined } from '@ant-design/icons';
import { useWallet } from '@suiet/wallet-kit';
import { Input, message, Modal, Switch } from 'antd';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import block from 'assets/images/common/block.svg';
import gift from 'assets/images/tickets/gift.svg';
import { PageContainer } from 'components/PageContainer';
import { useAllProjectsWithTickets } from 'hooks/useAllProjectsWithTickets';
import { useAppSelector } from 'store/hooks';
import { getTickets } from 'utils/aptos-sdk';
import { getProjectList } from 'utils/backendApi';
import { AptosWalletName, ChainName, Precision, TokenName } from 'utils/enums';
import { ProjectDetailWithTickets } from 'utils/models';
import { getWalletApi } from 'utils/wallets';

interface ModalInfo {
  isInfoModalOpen: boolean;
  ticketId?: string;
}

interface ModalStatus {
  isModalOpen: boolean;
  requestStatus?: 'success' | 'pending' | 'fail';
  requestValue?: string;
  info?: string;
}

export const Tickets: FC = () => {
  const navigate = useNavigate();
  const wallet = useWallet();

  const { address, connectedWallet } = useAppSelector(state => state.walletStatesSlice);
  const { chain, suiConfig } = useAppSelector(state => state.projectsSlice);
  const projectList = useAllProjectsWithTickets();
  const allProjectTickets = useAppSelector(state => state.projectsSlice.allProjectTickets);
  const [liveOnly, setLiveOnly] = useState(true);
  const [{ isInfoModalOpen, ticketId }, setInfoModal] = useState<ModalInfo>({
    isInfoModalOpen: false,
  });
  const [{ isModalOpen, requestStatus, requestValue, info }, setModalStatus] =
    useState<ModalStatus>({
      isModalOpen: false,
    });
  const [selectProjectId, setSelectedProjectId] = useState<undefined | string>();
  const [receiver, setReceiver] = useState('');
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);

  const send = async () => {
    if (connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    if (receiver === '') {
      message.error('Invalid Receiver Address');
      return;
    }
    setInfoModal({ isInfoModalOpen: false });
    let requestValue = '';
    if (ticketId === 'all') {
      requestValue = `${
        selectProjectId === undefined ? 0 : allProjectTickets[selectProjectId].length
      } Tickets`;
    } else {
      requestValue = '1 Ticket';
    }
    setModalStatus({
      isModalOpen: true,
      requestStatus: 'pending',
      requestValue,
      info: 'Please confirm the transaction from your wallet.',
    });
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.transferTicket(
          receiver,
          parseInt(selectProjectId as string),
          ticketId as string,
        );
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const projectDetail = projectList.find(
          project => project.key === selectProjectId,
        ) as ProjectDetailWithTickets;
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'transfer_tickets',
              typeArguments: [projectDetail.reward_type as string, projectDetail.fund_type],
              arguments: [
                suiConfig.objectId.UsersBag,
                suiConfig.objectId.TicketsBag,
                projectDetail.key,
                receiver,
                [ticketId as string],
              ],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Send failure');
        }
      }
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'success',
        requestValue,
        info: 'You have successfully sent tickets!',
      });
    } catch (err: any) {
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'fail',
        requestValue,
        info: err.toString === undefined ? err : err.toString(),
      });
    }
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  return (
    <PageContainer>
      <div className="flex h-[72px] items-center">
        <div className="text-[24px] font-semibold">Ticket Management</div>
        <div className="ml-[16px] flex items-center gap-[8px] rounded-[8px] bg-gray px-[16px] py-[8px]">
          <div className="text-[15px] font-semibold">Live Only</div>
          <Switch checked={liveOnly} onChange={() => setLiveOnly(!liveOnly)} />
        </div>
      </div>
      <div className="flex gap-[16px]">
        <div className={`duration-200 ${selectProjectId === undefined ? 'w-full' : 'w-[340px]'}`}>
          <div className="flex h-[60px] items-center text-[13px]  font-bold text-gray2">
            <div className="flex-[2]">Market</div>
            <div className={`flex-[1] ${selectProjectId === undefined ? '' : 'hidden'}`}>
              Ticket Amount
            </div>
            <div className={`flex-[1.5] ${selectProjectId === undefined ? '' : 'hidden'}`}>
              Launch Date
            </div>
            <div className={`flex-[1] ${selectProjectId === undefined ? '' : 'hidden'}`}>
              Status
            </div>
            <div className={`flex-[1] ${selectProjectId === undefined ? '' : 'hidden'}`} />
          </div>
          <div className="mb-[12px] h-[1px] w-full bg-gray" />
          <div className="hide-scrollbar h-[600px]">
            {projectList.filter(project => {
              if (liveOnly) {
                return project.isLive === true;
              } else {
                return true;
              }
            }).length > 0 ? (
              projectList
                .filter(project => {
                  if (liveOnly) {
                    return project.isLive === true;
                  } else {
                    return true;
                  }
                })
                .map((project, i: number) => (
                  <div
                    key={i}
                    className={`flex h-[104px] items-center duration-300 hover:bg-gray ${
                      selectProjectId === project.key ? 'bg-gray' : ''
                    }`}
                  >
                    <div className="flex flex-[2] items-center gap-[10px]">
                      <img
                        src={project.url}
                        className="h-[80px] w-[80px] cursor-pointer rounded-[8px]"
                        onClick={() => navigate(`/${chain}/detail/${project.key}`)}
                      />
                      <div>
                        <div
                          className="cursor-pointer text-[15px] font-bold hover:underline hover:underline-offset-4"
                          onClick={() => navigate(`/${chain}/detail/${project.key}`)}
                        >
                          {project.description}
                        </div>
                        <div className="mt-[4px] text-[13px] font-semibold text-gray2">
                          {project.type === 'coin'
                            ? `Value ${new BigNumber(project.reward_val)
                                .shiftedBy(Precision[chain])
                                .toFormat()} ${TokenName[chain]}`
                            : `Value ${new BigNumber(project.reward_val)} NFT`}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex-[1] text-[14px] font-semibold text-gray2 ${
                        selectProjectId === undefined ? '' : 'hidden'
                      }`}
                    >
                      {project.ticketAmount}
                    </div>
                    <div
                      className={`flex-[1.5] text-[14px] font-semibold text-gray2 ${
                        selectProjectId === undefined ? '' : 'hidden'
                      }`}
                    >
                      {`${moment(parseInt(project.create_time) * 1000)
                        .utc()
                        .format('D MMM, YYYY hh:mm:ss')}`}
                    </div>
                    <div className={`flex-[1] ${selectProjectId === undefined ? '' : 'hidden'}`}>
                      <div
                        className={`w-[80px] rounded-[6px] py-[5px] text-center text-[15px] font-semibold ${
                          project.isLive === true
                            ? 'bg-green text-white'
                            : project.isWinner === true
                            ? 'linear-border'
                            : 'bg-purple2 text-black'
                        }`}
                      >
                        {project.isLive === true
                          ? 'Live'
                          : project.isWinner === true
                          ? 'You Win'
                          : 'Expired'}
                      </div>
                    </div>
                    <div className={`flex-[1] ${selectProjectId === undefined ? '' : 'hidden'}`}>
                      <button
                        className={`h-[48px] w-[150px] rounded-[12px] border-[2px] border-white ${
                          project.isLive !== true
                            ? 'cursor-not-allowed !border-gray2 !bg-gray2'
                            : ''
                        }`}
                        onClick={
                          project.isLive !== true
                            ? undefined
                            : () =>
                                setSelectedProjectId(
                                  project.key === selectProjectId ? undefined : project.key,
                                )
                        }
                      >
                        Send
                      </button>
                    </div>
                  </div>
                ))
            ) : (
              <div className="mt-[100px] text-center">
                <img src={block} className="mx-auto animate-bounce" />
                <div className="mt-[50px]">
                  <span className="text-[12px] font-medium text-gray2">
                    You haven't participated in any activitie yet.
                  </span>
                  <span
                    className="linear-text ml-[10px] cursor-pointer text-[12px] font-semibold underline decoration-gray2 underline-offset-4"
                    onClick={() => navigate(`/${chain}/home/#market`)}
                  >
                    Go to Market
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {selectProjectId !== undefined ? (
          <div className="flex-1">
            <div className="flex h-[60px] items-center justify-end text-[13px] font-bold text-gray2">
              <CloseOutlined
                className="cursor-pointer rounded-full bg-gray p-[8px] text-white"
                onClick={() => setSelectedProjectId(undefined)}
              />
            </div>
            <div className="mb-[12px] h-[1px] w-full bg-gray" />
            <div className="max-h-[600px]">
              <div className="hide-scrollbar grid max-h-[525px] grid-cols-2 gap-[10px]">
                {allProjectTickets[selectProjectId].map((ticket: any, i: number) => (
                  <div
                    key={i}
                    className="cursor-pointer rounded-[8px] border-[1px] border-gray2 py-[12px] text-center duration-300 hover:bg-gray"
                    onClick={() =>
                      setInfoModal({ isInfoModalOpen: true, ticketId: ticket.ticket_code })
                    }
                  >
                    <div className="text-[15px] font-bold">Ticket #{ticket.ticket_code}</div>
                    <div className="mt-[4px] text-[13px] font-semibold text-gray2">
                      {`${new BigNumber(
                        projectList.find(project => project.key === selectProjectId)
                          ?.ticket_value ?? 0,
                      )
                        .shiftedBy(Precision[chain])
                        .toFormat()} ${TokenName[chain]}`}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-[25px] flex justify-end">
                <button
                  className="h-[50px] w-[150px] rounded-[12px] bg-purple"
                  onClick={() => setInfoModal({ isInfoModalOpen: true, ticketId: 'all' })}
                >
                  Send All
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        width={560}
        footer={null}
        open={isInfoModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        onCancel={() => setInfoModal({ isInfoModalOpen: false })}
      >
        <div className="bg-black2 p-[24px] text-white">
          <div className="flex items-center gap-[16px]">
            <img src={gift} />
            <div className="text-[20px] font-semibold">Send Tickets</div>
          </div>
          <div>
            <div className="mt-[40px] mb-[12px] flex items-center gap-[5px]">
              <div className="text-[15px] font-medium">
                {`${projectList.find(project => project.key === selectProjectId)?.description} (${
                  selectProjectId === undefined
                    ? 0
                    : ticketId === 'all'
                    ? allProjectTickets[selectProjectId].length
                    : 1
                })`}
              </div>
            </div>
            <div className="mb-[12px] h-[1px] w-full bg-gray" />
            <div className="flex flex-wrap gap-[16px]">
              {ticketId === 'all' ? (
                selectProjectId !== undefined &&
                allProjectTickets[selectProjectId].map((v: any) => (
                  <div
                    key={v.ticket_code}
                    className="rounded-[16px] bg-black px-[16px] py-[8px] text-[16px] font-semibold"
                  >
                    {v.ticket_code}
                  </div>
                ))
              ) : (
                <div className="rounded-[16px] bg-black px-[16px] py-[8px] text-[16px] font-semibold">
                  {ticketId}
                </div>
              )}
            </div>
          </div>
          <div className="mt-[40px] flex items-center gap-[40px]">
            <div className="shrink-0 text-[18px] font-semibold text-gray2">Send to</div>
            <div
              className={`relative w-full flex-1 rounded-[5px] border-[1px] border-transparent ${
                isAddressInvalid ? 'border-[1px] !border-red' : ''
              }`}
            >
              <Input
                onBlur={() => {
                  if (receiver === '' || !new RegExp('^0x[0-9a-fA-F]{64}$', 'gim').test(receiver)) {
                    setIsAddressInvalid(true);
                  } else {
                    setIsAddressInvalid(false);
                  }
                }}
                bordered={false}
                className="rounded-[5px] !bg-gray !text-left text-white"
                value={receiver}
                onChange={e => {
                  if (
                    e.target.value === '' ||
                    !new RegExp('^0x[0-9a-fA-F]{64}$', 'gim').test(e.target.value)
                  ) {
                    setIsAddressInvalid(true);
                  } else {
                    setIsAddressInvalid(false);
                  }
                  setReceiver(e.target.value);
                }}
              />
              {isAddressInvalid ? (
                <div className="absolute top-[100%] mt-[5px] text-red">Invalid address</div>
              ) : null}
            </div>
          </div>
          <div className="mt-[30px] h-[1px] w-full bg-gray" />
          <div className="mt-[40px] flex gap-[8px]">
            <button
              className="h-[50px] w-[150px] flex-1 rounded-[12px] border-[2px] border-gray text-[15px] font-bold"
              onClick={() => setInfoModal({ isInfoModalOpen: false })}
            >
              Cancel
            </button>
            <button
              className={`h-[50px] w-[150px] flex-1 rounded-[12px] border-[2px] border-purple bg-purple text-[15px] font-bold ${
                isAddressInvalid ? 'cursor-not-allowed !border-gray2 bg-gray2' : ''
              }`}
              onClick={isAddressInvalid ? undefined : () => send()}
            >
              Send Now
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        width={560}
        footer={null}
        open={isModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        maskClosable={requestStatus === 'pending' ? false : true}
        onCancel={() => setModalStatus({ isModalOpen: false })}
      >
        <div className="rounded-[16px] bg-black2 pt-[80px] pb-[50px] text-center">
          <img
            src={
              requestStatus === 'pending'
                ? '/common/pending.svg'
                : requestStatus === 'success'
                ? '/common/check.svg'
                : '/common/fail.svg'
            }
            className={`mx-auto ${requestStatus === 'pending' && 'animate-spin'}`}
          />
          <div className="mt-[20px] text-[20px] font-semibold text-white">
            {requestStatus === 'pending'
              ? 'Wait for Confirmation !'
              : requestStatus === 'success'
              ? 'Succeeded !'
              : 'Failed !'}
          </div>
          <div className="mt-[8px] text-[48px] font-semibold text-white">{requestValue}</div>
          <div className="mt-[16px] text-[15px] font-semibold text-gray2">{info}</div>
        </div>
      </Modal>
    </PageContainer>
  );
};
