import { Tooltip } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Archive } from 'assets/images/common/archive.svg';
import { ReactComponent as Leaderboard } from 'assets/images/common/leaderboard.svg';
import { ReactComponent as Receipt } from 'assets/images/detail/receipt.svg';
import { ReactComponent as Store } from 'assets/images/detail/store.svg';
import { ReactComponent as Ticket } from 'assets/images/detail/ticket.svg';
import { ReactComponent as Trophy } from 'assets/images/detail/trophy.svg';
import { useAppSelector } from 'store/hooks';

export const Menu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { chain } = useAppSelector(state => state.projectsSlice);

  return (
    <div className="fixed top-[122px] left-[122px] z-[999] flex flex-col items-end gap-[24px]">
      <Tooltip title="Market" placement="left">
        <div
          className={`flex h-[64px] w-[64px] cursor-pointer items-center justify-center rounded-full bg-gray text-gray2 duration-300 hover:bg-purple hover:text-white ${
            location.pathname === `/${chain}/home` ? '!bg-purple text-white' : ''
          }`}
          onClick={() => navigate(`/${chain}/home`)}
        >
          <Store />
        </div>
      </Tooltip>
      <Tooltip title="My Tickets" placement="left">
        <div
          className={`flex h-[64px] w-[64px] cursor-pointer items-center justify-center rounded-full bg-gray text-gray2 duration-300 hover:bg-purple hover:text-white ${
            location.pathname === `/${chain}/tickets` ? '!bg-purple text-white' : ''
          }`}
          onClick={() => navigate(`/${chain}/tickets`)}
        >
          <Ticket />
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div>
            <div>Claim</div>
            <div>& History</div>
          </div>
        }
        placement="left"
      >
        <div
          className={`flex h-[64px] w-[64px] cursor-pointer items-center justify-center rounded-full bg-gray text-gray2 duration-300 hover:bg-purple hover:text-white ${
            location.pathname === `/${chain}/claim` ? '!bg-purple text-white' : ''
          }`}
          onClick={() => navigate(`/${chain}/claim`)}
        >
          <Receipt />
        </div>
      </Tooltip>
      <Tooltip title="Results" placement="left">
        <div
          className={`flex h-[64px] w-[64px] cursor-pointer items-center justify-center rounded-full bg-gray text-gray2 duration-300 hover:bg-purple hover:text-white ${
            location.pathname.indexOf('results') >= 0 ? '!bg-purple text-white' : ''
          }`}
          onClick={() => navigate(`/${chain}/results/prizes`)}
        >
          <Trophy className="h-[24px] w-[24px]" />
        </div>
      </Tooltip>
      {location.pathname.indexOf('results') >= 0 ? (
        <div>
          <Tooltip title="Prizes" placement="left">
            <div
              className={`flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-full border-[2px] border-gray bg-gray text-gray2 duration-300 hover:border-purple hover:bg-black hover:text-white ${
                location.pathname === `/${chain}/results/prizes`
                  ? 'border-purple !bg-black text-white'
                  : ''
              }`}
              onClick={() => navigate(`/${chain}/results/prizes`)}
            >
              <Archive />
            </div>
          </Tooltip>
          <Tooltip title="Top10" placement="left">
            <div
              className={`mt-[24px] flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-full border-[2px] border-gray bg-gray text-gray2 duration-300 hover:border-purple hover:bg-black hover:text-white ${
                location.pathname === `/${chain}/results/top10`
                  ? 'border-purple !bg-black text-white'
                  : ''
              }`}
              onClick={() => navigate(`/${chain}/results/top10`)}
            >
              <Leaderboard />
            </div>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
