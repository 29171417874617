import { FC } from 'react';
import { ReactComponent as Download } from 'assets/images/common/download.svg';
import background1 from 'assets/images/pressKit/background1.svg';
import background2 from 'assets/images/pressKit/background2.svg';
import background3 from 'assets/images/pressKit/background3.svg';
import background4 from 'assets/images/pressKit/background4.svg';
import { PageContainer } from 'components/PageContainer';

export const PressKit: FC = () => {
  return (
    <PageContainer className="font-inter">
      <div className="mt-[64px] text-[24px] font-bold">1dollar Press Kit</div>
      <div className="mt-[32px] text-gray2">Recommended in night mode / dark background</div>
      <div className="flex justify-between gap-[32px]">
        <div className="flex-1">
          <img src={background4} className="mt-[16px] mb-[10px] w-full" />
          <div className="flex gap-[10px]">
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background4.svg"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>SVG</span>
              </a>
            </button>
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background4.png"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>PNG</span>
              </a>
            </button>
          </div>
        </div>
        <div className="flex-1">
          <img src={background2} className="mt-[16px] mb-[10px] w-full" />
          <div className="flex gap-[10px]">
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background2.svg"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>SVG</span>
              </a>
            </button>
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background2.png"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>PNG</span>
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-[32px] text-gray2">Recommended in daylight mode/light background</div>
      <div className="flex justify-between gap-[32px]">
        <div className="flex-1">
          <img src={background3} className="mt-[16px] mb-[10px] w-full" />
          <div className="flex gap-[10px]">
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background3.svg"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>SVG</span>
              </a>
            </button>
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background3.png"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>PNG</span>
              </a>
            </button>
          </div>
        </div>
        <div className="flex-1">
          <img src={background1} className="mt-[16px] mb-[10px] w-full" />
          <div className="flex gap-[10px]">
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background1.svg"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>SVG</span>
              </a>
            </button>
            <button className="rounded-[12px] border-[2px] border-gray2 bg-black duration-300 hover:border-white">
              <a
                href="/pressKit/background1.png"
                download
                className="flex items-center gap-[10px] px-[16px] py-[8px] font-inter text-[16px] font-medium text-gray2 duration-300 hover:text-white"
              >
                <Download />
                <span>PNG</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
