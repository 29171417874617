import { MartianWallet, SuietWallet, SuiWallet, WalletProvider } from '@suiet/wallet-kit';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';
import '@suiet/wallet-kit/style.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <WalletProvider defaultWallets={[SuietWallet, SuiWallet, MartianWallet]}>
        <App />
      </WalletProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
