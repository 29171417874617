import { Select } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/common/logo.svg';
import Wallet from 'components/Wallet';
import { updateConnectStatus } from 'store/features/walletStatesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ChainName } from 'utils/enums';

export const Header: FC = () => {
  const navigate = useNavigate();
  const { chain } = useAppSelector(state => state.projectsSlice);

  const dispatch = useAppDispatch();

  const changeChain = (chainName: ChainName) => {
    localStorage.removeItem('LAST_CONNECTED_WALLET_NAME');
    dispatch(updateConnectStatus({ connectedWallet: null, address: '' }));
    navigate(`/${chainName}/home`);
  };

  return (
    <div>
      <div className="fixed top-0 z-[999] h-[30px] w-full bg-purple text-center leading-[30px] backdrop-blur-[10px]">
        This is a beta version on devnet
      </div>
      <div className="fixed top-[30px] z-[999] flex h-[88px] w-full items-center justify-center bg-black/80 backdrop-blur-[10px]">
        <div className="absolute left-[210px] flex items-center gap-[10px]">
          <div className="font-inter font-bold">Chain:</div>
          <Select<ChainName>
            value={chain}
            className="w-[100px]"
            onChange={v => changeChain(v)}
            options={[
              { value: ChainName.apots, label: 'APTOS' },
              { value: ChainName.sui, label: 'SUI' },
            ]}
          />
        </div>
        <img
          src={logo}
          className="cursor-pointer duration-300 hover:scale-110"
          onClick={() => navigate(`/${chain}/home`, { replace: true })}
        />
        <Wallet className="absolute top-1/2 right-[210px] -translate-y-1/2" />
      </div>
    </div>
  );
};
