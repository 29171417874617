import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Discord } from 'assets/images/common/discord.svg';
import { ReactComponent as File } from 'assets/images/common/file.svg';
import { ReactComponent as Twitter } from 'assets/images/common/twitter.svg';
import { useAppSelector } from 'store/hooks';

export const Footer: FC = () => {
  const navigate = useNavigate();
  const { chain } = useAppSelector(state => state.projectsSlice);

  return (
    <div className="my-[64px] flex items-center justify-center gap-[16px]">
      <button
        className="flex items-center gap-[10px] rounded-[12px] border-[2px] border-gray2 px-[60px] py-[12px] font-inter text-[15px] font-bold text-gray2 duration-300 hover:border-white hover:text-white"
        onClick={() => window.open('https://twitter.com/1dollarOfficial')}
      >
        <Twitter />
        <span>Twitter</span>
      </button>
      <button
        className="flex items-center gap-[10px] rounded-[12px] border-[2px] border-gray2 px-[60px] py-[12px] font-inter text-[15px] font-bold text-gray2 duration-300 hover:border-white hover:text-white"
        onClick={() => window.open('https://discord.gg/ff3hDnm8')}
      >
        <Discord />
        <span>Discord</span>
      </button>
      <button
        className="flex items-center gap-[10px] rounded-[12px] border-[2px] border-gray2 px-[60px] py-[12px] font-inter text-[15px] font-bold text-gray2 duration-300 hover:border-white hover:text-white"
        onClick={() => navigate(`/${chain}/presskit`)}
      >
        <File />
        <span>Press Kit</span>
      </button>
    </div>
  );
};
