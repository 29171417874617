import { FC, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Claim } from 'pages/claim';
import { Detail } from 'pages/detail';
import { PressKit } from 'pages/presskit';
import { Prizes } from 'pages/prizes';
import { Tickets } from 'pages/tickets';
import { Top10 } from 'pages/top10';
import { updateChain } from 'store/features/projectsSlice';
import { useAppSelector } from 'store/hooks';
import { getConfigList } from 'utils/backendApi';
import { ChainName } from 'utils/enums';
import { initWallets } from 'utils/wallets';
import { Index } from './pages/home/index';

export const App: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { chain } = useAppSelector(state => state.projectsSlice);

  useEffect(() => {
    if (chain === ChainName.apots) {
      initWallets();
    } else {
      getConfigList();
    }
  }, [dispatch, chain]);

  useLayoutEffect(() => {
    dispatch(updateChain(location.pathname.split('/')[1] as ChainName));
    document.scrollingElement?.scrollTo(0, 0);
  }, [location.pathname, dispatch]);

  return (
    <Routes>
      <Route path=":chain/home" element={<Index />} />
      <Route path=":chain/detail/:key" element={<Detail />} />
      <Route path=":chain/tickets" element={<Tickets />} />
      <Route path=":chain/claim" element={<Claim />} />
      <Route path=":chain/results/prizes" element={<Prizes />} />
      <Route path=":chain/results/top10" element={<Top10 />} />
      <Route path=":chain/presskit" element={<PressKit />} />
      <Route path="*" element={<Navigate to="aptos/home" replace />} />
    </Routes>
  );
};
