import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AptosWalletName, SuiWalletName } from 'utils/enums';
import { ConnectStatus, WalletState } from 'utils/models';

export const walletStatesSlice = createSlice({
  name: 'walletStatesSlice',
  initialState: {
    connectWalletVisible: false,
    connectedWallet: null as AptosWalletName | SuiWalletName | null,
    address: '',
    [AptosWalletName.Martian]: {
      name: AptosWalletName.Martian,
      installed: false,
    } as WalletState,
    [AptosWalletName.Petra]: {
      name: AptosWalletName.Petra,
      installed: false,
    } as WalletState,
  },
  reducers: {
    setConnectWalletVisible: (state, action: PayloadAction<boolean>) => {
      state.connectWalletVisible = action.payload;
    },
    updateWalletState: (state, action: PayloadAction<WalletState>) => {
      Object.assign(state[action.payload.name], action.payload);
    },
    updateConnectStatus: (state, action: PayloadAction<ConnectStatus>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setConnectWalletVisible, updateWalletState, updateConnectStatus } =
  walletStatesSlice.actions;

export default walletStatesSlice.reducer;
