import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import block from 'assets/images/common/block.svg';
import top1 from 'assets/images/top10/top1.svg';
import top2 from 'assets/images/top10/top2.svg';
import top3 from 'assets/images/top10/top3.svg';
import { PageContainer } from 'components/PageContainer';
import { useAppSelector } from 'store/hooks';
import { getTopWinners } from 'utils/backendApi';
import { TokenName } from 'utils/enums';
import { TopWinner } from 'utils/models';

export const Top10: FC = () => {
  const navigate = useNavigate();

  const { chain } = useAppSelector(state => state.projectsSlice);

  const [winnerList, setWinnerList] = useState<TopWinner[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<'APT' | 'NFT'>('APT');

  useEffect(() => {
    (async () => {
      const winnerList = await getTopWinners(selectedFilter);
      if (winnerList !== undefined) {
        setWinnerList(winnerList);
      }
    })();
  }, [selectedFilter]);

  return (
    <PageContainer className="font-inter">
      <div className="flex h-[72px] items-center">
        <div className="text-[24px] font-semibold">Top 10</div>
        <button
          className={`ml-[24px] rounded-[8px] bg-gray px-[16px] py-[8px] text-[15px] font-semibold duration-300 ${
            selectedFilter === 'APT' ? 'bg-purple2 text-black' : ''
          }`}
          onClick={() => setSelectedFilter('APT')}
        >
          {TokenName[chain]}
        </button>
        <button
          className={`ml-[8px] rounded-[8px] bg-gray px-[16px] py-[8px] text-[15px] font-semibold duration-300 ${
            selectedFilter === 'NFT' ? 'bg-purple2 text-black' : ''
          }`}
          onClick={() => setSelectedFilter('NFT')}
        >
          NFT
        </button>
      </div>
      <div>
        {winnerList.length > 0 ? (
          <div>
            {winnerList[0] !== undefined ? (
              <div
                className="relative cursor-pointer"
                onClick={() =>
                  window.open(`https://explorer.aptoslabs.com/account/${winnerList[0].winner}`)
                }
              >
                <img src={top1} className="w-full" />
                <div className="absolute left-1/4 top-1/2 flex h-full -translate-y-1/2 flex-col items-start justify-between py-[12px] 2xl:py-[24px]">
                  <div className="rounded-[4px] border-[2px] border-yellow px-[8px] py-[4px] text-[12px] font-bold text-yellow">
                    TOTAL WINNING
                  </div>
                  <div className="font-inter text-[40px] font-bold text-white 2xl:text-[60px]">
                    {winnerList[0].amount} {selectedFilter}
                  </div>
                  <div className="max-w-[500px] overflow-hidden text-ellipsis font-inter text-[14px] font-bold text-white 2xl:text-[18px]">
                    {winnerList[0].winner}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="mt-[24px] flex items-center gap-[40px]">
              {winnerList[1] !== undefined ? (
                <div
                  className="relative max-w-[calc(50%-20px)] flex-1 cursor-pointer"
                  onClick={() =>
                    window.open(`https://explorer.aptoslabs.com/account/${winnerList[1].winner}`)
                  }
                >
                  <img src={top2} className="w-full" />
                  <div className="absolute left-1/3 top-1/2 flex h-[80%] -translate-y-1/2 flex-col items-start justify-between">
                    <div className="rounded-[4px] border-[2px] border-blue px-[8px] py-[4px] text-[12px] font-bold text-blue">
                      TOTAL WINNING
                    </div>
                    <div className="font-inter text-[40px] font-bold text-white">
                      {winnerList[1].amount} {selectedFilter}
                    </div>
                    <div className="max-w-[250px] overflow-hidden text-ellipsis font-inter text-[14px] font-normal text-white">
                      {winnerList[1].winner}
                    </div>
                  </div>
                </div>
              ) : null}
              {winnerList[2] !== undefined ? (
                <div
                  className="relative flex-1 cursor-pointer"
                  onClick={() =>
                    window.open(`https://explorer.aptoslabs.com/account/${winnerList[2].winner}`)
                  }
                >
                  <img src={top3} className="w-full" />
                  <div className="absolute left-1/3 top-1/2 flex h-[80%] -translate-y-1/2 flex-col items-start justify-between">
                    <div className="rounded-[4px] border-[2px] border-blue px-[8px] py-[4px] text-[12px] font-bold text-blue">
                      TOTAL WINNING
                    </div>
                    <div className="font-inter text-[40px] font-bold text-white">
                      {winnerList[2].amount} {selectedFilter}
                    </div>
                    <div className="max-w-[250px] overflow-hidden text-ellipsis font-inter text-[14px] font-normal text-white">
                      {winnerList[2].winner}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {winnerList
              .filter((topWinner, i) => i > 2)
              .map((topWinner, i) => (
                <div
                  key={i}
                  className="flex h-[104px] cursor-pointer items-center duration-300 hover:bg-gray"
                  onClick={() =>
                    window.open(`https://explorer.aptoslabs.com/account/${topWinner.winner}`)
                  }
                >
                  <div className="flex-[1] font-inter text-[18px] font-semibold text-white">
                    #{i + 1}
                  </div>
                  <div className="flex flex-[8] justify-center text-[14px] font-semibold text-gray2">
                    <div className="linear-border inline-block rounded-[4px] p-[2px]">
                      <div className="relative rounded-[4px] bg-black2 px-[8px] py-[4px] text-[12px] text-white">
                        {topWinner.winner}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-[3] items-center gap-[16px] text-[14px] font-semibold text-gray2">
                    <div className="rounded-[4px] border-[2px] border-yellow px-[8px] py-[4px] text-[12px] font-bold text-yellow">
                      TOTAL WINNING
                    </div>
                    <div className="font-inter text-[14px] font-semibold text-white">
                      {topWinner.amount} {selectedFilter}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="mt-[100px] text-center">
            <img src={block} className="mx-auto animate-bounce" />
            <div className="mt-[50px]">
              <span className="text-[12px] font-medium text-gray2">
                You haven't participated in any activitie yet.
              </span>
              <span
                className="linear-text ml-[10px] cursor-pointer text-[12px] font-semibold underline decoration-gray2 underline-offset-4"
                onClick={() => navigate(`/${chain}/home/#market`)}
              >
                Go to Market
              </span>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
