import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { getTickets } from 'utils/aptos-sdk';
import { ProjectDetailWithTickets } from 'utils/models';
import { useProjectList } from './useProjectList';

export const useAllProjectsWithTickets = (): ProjectDetailWithTickets[] => {
  const { address } = useAppSelector(state => state.walletStatesSlice);
  const projectList = useProjectList();
  const allProjectTickets = useAppSelector(state => state.projectsSlice.allProjectTickets);
  const { chain } = useAppSelector(state => state.projectsSlice);

  useEffect(() => {
    if (address !== '') {
      getTickets(address);
    }
  }, [address, chain]);

  const res = useMemo(() => {
    return projectList
      .filter(project => allProjectTickets[project.key] !== undefined)
      .map(project => {
        return {
          ...project,
          isWinner: address !== '' && project.winner.vec[0] === address,
          ticketAmount: allProjectTickets[project.key].length,
          couldRefund: allProjectTickets[project.key]?.[0]?.state === '0',
        };
      });
  }, [projectList, allProjectTickets, address]);

  return res;
};
