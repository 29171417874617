import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { getTickets } from 'utils/aptos-sdk';
import { ProjectDetailWithTickets } from 'utils/models';
import { useAllProjectsWithTickets } from './useAllProjectsWithTickets';

export const useWinOrExpired = (): ProjectDetailWithTickets[] => {
  const { address } = useAppSelector(state => state.walletStatesSlice);
  const projectList = useAllProjectsWithTickets();
  const { chain } = useAppSelector(state => state.projectsSlice);

  useEffect(() => {
    if (address !== '') {
      getTickets(address);
    }
  }, [address, chain]);

  const res = useMemo(() => {
    return projectList.filter(
      project => (project.isExpired && !project.haveWinner) || project.isWinner,
    );
  }, [projectList]);

  return res;
};
