import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChainName } from 'utils/enums';
import { ProjectDetail, SuiConfig } from 'utils/models';

export const projectsSlice = createSlice({
  name: 'projectsSlice',
  initialState: {
    chain: 'sui' as ChainName,
    projectList: [] as ProjectDetail[],
    allProjectTickets: {} as { [key: string]: any },
    records: [] as any[],
    suiConfig: null as SuiConfig | null,
  },
  reducers: {
    updateProjectList: (state, action: PayloadAction<ProjectDetail[]>) => {
      state.projectList = action.payload;
    },
    updateTickets: (state, action: PayloadAction<any[]>) => {
      state.allProjectTickets = action.payload;
    },
    updateRecords: (state, action: PayloadAction<any[]>) => {
      state.records = action.payload;
    },
    updateChain: (state, action: PayloadAction<ChainName>) => {
      state.chain = action.payload;
    },
    setSuiConfig: (state, action: PayloadAction<any>) => {
      state.suiConfig = action.payload;
    },
  },
});

export const { updateProjectList, updateTickets, updateRecords, updateChain, setSuiConfig } =
  projectsSlice.actions;

export default projectsSlice.reducer;
