import { CloseOutlined } from '@ant-design/icons';
import Spline from '@splinetool/react-spline';
import { useWallet } from '@suiet/wallet-kit';
import { Input, InputNumber, message, Modal, Popover } from 'antd';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import block from 'assets/images/common/block.svg';
import coin from 'assets/images/common/nft4.png';
import { ReactComponent as ArrowLeft } from 'assets/images/detail/arrow-left.svg';
import dashboard from 'assets/images/detail/dashboard.svg';
import fire from 'assets/images/detail/fire.svg';
import group from 'assets/images/detail/group.svg';
import notes from 'assets/images/detail/notes.svg';
import open from 'assets/images/detail/open.svg';
import { ReactComponent as Receipt } from 'assets/images/detail/receipt.svg';
import scoreboard from 'assets/images/detail/scoreboard.svg';
import { ReactComponent as Ticket } from 'assets/images/detail/ticket.svg';
import { ReactComponent as Trophy } from 'assets/images/detail/trophy.svg';
import winnerIcon from 'assets/images/detail/winner.svg';
import gift from 'assets/images/tickets/gift.svg';
import { PageContainer } from 'components/PageContainer';
import { useProjectList } from 'hooks/useProjectList';
import { useTicket } from 'hooks/useTicket';
import { updateRecords } from 'store/features/projectsSlice';
import { setConnectWalletVisible } from 'store/features/walletStatesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBalance, getTickets } from 'utils/aptos-sdk';
import { getProjectList, getSelledTickets } from 'utils/backendApi';
import { TOKEN_NAME } from 'utils/config';
import { AptosWalletName, ChainName, Precision, TokenName } from 'utils/enums';
import { hideAddress } from 'utils/formatters';
import { getBalanceSui, getCoinsObjectIdReduceGas } from 'utils/sui-sdk';
import { getWalletApi } from 'utils/wallets';

interface ModalStatus {
  isModalOpen: boolean;
  requestStatus?: 'success' | 'pending' | 'fail';
  requestValue?: string;
  type?: 'buySuccess';
  info?: string;
}

interface ModalInfo {
  isInfoModalOpen: boolean;
  ticketId?: string;
}

export const Detail: FC = () => {
  const pathParams = useParams();
  const navigate = useNavigate();
  const wallet = useWallet();

  const dispatch = useAppDispatch();

  const { address, connectedWallet } = useAppSelector(state => state.walletStatesSlice);
  const { chain, suiConfig } = useAppSelector(state => state.projectsSlice);
  const records = useAppSelector(state => state.projectsSlice.records);

  const projectList = useProjectList();
  const tickets = useTicket();

  const [ticketAmount, setTicketAmount] = useState(1);
  const [balance, setBalance] = useState(new BigNumber(0));
  const [leftTime, setLeftTime] = useState<string>();
  const [{ isModalOpen, requestStatus, type, requestValue, info }, setModalStatus] =
    useState<ModalStatus>({
      isModalOpen: false,
    });
  const [winnerModalOpen, setWinnerModalOpen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [{ isInfoModalOpen, ticketId }, setInfoModal] = useState<ModalInfo>({
    isInfoModalOpen: false,
  });
  const [receiver, setReceiver] = useState('');
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);

  const projectDetail = useMemo(() => {
    return pathParams.key !== undefined
      ? projectList.find(project => project.key === pathParams.key)
      : undefined;
  }, [projectList, pathParams]);

  const progress = useMemo(() => {
    return projectDetail !== undefined
      ? `${new BigNumber(projectDetail.selled_tickets)
          .div(projectDetail.total_tickets)
          .times(100)
          .toString()}%`
      : 0;
  }, [projectDetail]);

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      if (projectDetail === undefined || projectDetail.isExpired || projectDetail.haveWinner) {
        clearInterval(intervalHandle);
      } else {
        let time = 0;
        if (projectDetail !== undefined) {
          time = new BigNumber(projectDetail.create_time)
            .plus(projectDetail.delay_time)
            .plus(projectDetail.selling_time)
            .times(1000)
            .minus(Date.now())
            .div(1000)
            .integerValue()
            .toNumber();
        }
        const hour =
          Math.floor(time / 3600) < 10 ? `0${Math.floor(time / 3600)}` : Math.floor(time / 3600);
        const minutes =
          Math.floor((time / 60) % 60) < 10
            ? `0${Math.floor((time / 60) % 60)}`
            : Math.floor((time / 60) % 60);
        const seconds = time % 60 < 10 ? `0${time % 60}` : time % 60;
        setLeftTime(`${hour}:${minutes}:${seconds}`);
      }
    }, 1000);
    return () => clearInterval(intervalHandle);
  }, [projectDetail]);

  useEffect(() => {
    if (projectDetail !== undefined) {
      (async () => {
        const response = await getSelledTickets(
          chain === ChainName.sui
            ? projectDetail.key
            : projectDetail.sell_tickets_event.guid.id.addr,
          chain === ChainName.sui
            ? undefined
            : parseInt(projectDetail.sell_tickets_event.guid.id.creation_num),
        );
        dispatch(updateRecords(response));
      })();
    }
  }, [projectDetail, dispatch, chain]);

  useEffect(() => {
    if (projectDetail !== undefined) {
      const leftTicket = new BigNumber(projectDetail.total_tickets)
        .minus(projectDetail.selled_tickets)
        .toNumber();
      setTicketAmount(leftTicket >= 5 ? 5 : leftTicket);
    }
  }, [projectDetail]);

  useEffect(() => {
    if (address !== '') {
      getTickets(address);
    }
  }, [address, chain]);

  useEffect(() => {
    if (projectDetail !== undefined && projectDetail.haveWinner) {
      setWinnerModalOpen(true);
    }
    if (projectDetail !== undefined && address !== '') {
      (async () => {
        if (chain === ChainName.apots) {
          setBalance(
            new BigNumber(await getBalance(address, projectDetail.fund_type)).shiftedBy(
              Precision[chain],
            ),
          );
        } else {
          setBalance(new BigNumber(await getBalanceSui(address)).shiftedBy(-9));
        }
      })();
    }
  }, [projectDetail, address, wallet, chain]);

  const buyTickets = async () => {
    if (projectDetail === undefined || pathParams.key === undefined || connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    const requestValue = `${new BigNumber(projectDetail.ticket_value)
      .shiftedBy(Precision[chain])
      .times(ticketAmount)
      .toFormat()} ${TokenName[chain]}`;
    setModalStatus({
      isModalOpen: true,
      requestStatus: 'pending',
      requestValue,
      info: 'Please confirm the transaction from your wallet.',
    });
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.buyTickets(parseInt(pathParams.key), ticketAmount, projectDetail.type);
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const objectIds = await getCoinsObjectIdReduceGas(address as string);
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'buy_tickets',
              typeArguments: [projectDetail.reward_type as string, projectDetail.fund_type],
              arguments: [
                pathParams.key,
                suiConfig.objectId.TicketsBag,
                suiConfig.objectId.UsersBag,
                objectIds,
                String(ticketAmount),
              ],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Bought failure');
        }
      }
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'success',
        requestValue,
        type: 'buySuccess',
        info: `You have successfully purchased ${ticketAmount} ${
          ticketAmount > 1 ? 'tickets !' : 'ticket !'
        }`,
      });
    } catch (err: any) {
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'fail',
        requestValue,
        info: err.toString === undefined ? err : err.toString(),
      });
    }
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  const handleChange = (value: number | null) => {
    if (value === null) {
      setTicketAmount(1);
    } else {
      setTicketAmount(value);
    }
  };

  const claim = async () => {
    if (projectDetail === undefined || pathParams.key === undefined || connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    let requestValue;
    if (projectDetail.haveWinner) {
      requestValue =
        projectDetail.type === 'coin'
          ? `${new BigNumber(projectDetail.reward_val).shiftedBy(Precision[chain]).toFormat()} ${
              TokenName[chain]
            }`
          : `${projectDetail.reward_val} NFT`;
    } else {
      requestValue = `${new BigNumber(projectDetail.ticket_value)
        .shiftedBy(Precision[chain])
        .times(tickets.length)
        .toFormat()} ${TokenName[chain]}`;
    }
    setModalStatus({
      isModalOpen: true,
      requestStatus: 'pending',
      requestValue,
      info: 'Please confirm the transaction from your wallet.',
    });
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.claimOrRefund(parseInt(pathParams.key), projectDetail.type);
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'claim_or_fund',
              typeArguments: [projectDetail.reward_type as string, projectDetail.fund_type],
              arguments: [projectDetail.key, suiConfig.objectId.UsersBag],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Claim failure');
        }
      }
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'success',
        requestValue,
        info: 'You have successfully claimed!',
      });
    } catch (err: any) {
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'fail',
        requestValue,
        info: err.toString === undefined ? err : err.toString(),
      });
    }
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  const send = async () => {
    if (projectDetail === undefined || connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    if (receiver === '') {
      message.error('Invalid Receiver Address');
      return;
    }
    setInfoModal({ isInfoModalOpen: false });
    let requestValue = '';
    if (ticketId === 'all') {
      requestValue = `${tickets.length} Tickets`;
    } else {
      requestValue = '1 Ticket';
    }
    setModalStatus({
      isModalOpen: true,
      requestStatus: 'pending',
      requestValue,
      info: 'Please confirm the transaction from your wallet.',
    });
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.transferTicket(receiver, parseInt(projectDetail.key), ticketId as string);
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'transfer_tickets',
              typeArguments: [projectDetail.reward_type as string, projectDetail.fund_type],
              arguments: [
                suiConfig.objectId.UsersBag,
                suiConfig.objectId.TicketsBag,
                projectDetail.key,
                receiver,
                ticketId === 'all' ? tickets : [ticketId],
              ],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Send failure');
        }
      }
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'success',
        requestValue,
        info: 'You have successfully sent tickets!',
      });
    } catch (err: any) {
      setModalStatus({
        isModalOpen: true,
        requestStatus: 'fail',
        requestValue,
        info: err.toString === undefined ? err : err.toString(),
      });
    }
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  return (
    <PageContainer>
      <div className="flex items-start gap-[40px]">
        <div
          className={`relative rounded-[8px] p-[3px] ${
            projectDetail !== undefined &&
            address !== '' &&
            projectDetail?.winner.vec[0] === address
              ? 'linear-border'
              : ''
          }`}
        >
          {projectDetail !== undefined &&
          !projectDetail.isExpired &&
          !projectDetail.haveWinner &&
          leftTime !== undefined ? (
            <div className="absolute left-[16px] top-[16px] rounded-[4px] bg-purple2 p-[8px] text-[18px] font-bold text-black">
              {leftTime}
            </div>
          ) : null}
          {projectDetail !== undefined && projectDetail.isExpired && !projectDetail.haveWinner ? (
            <div className="absolute left-[16px] top-[16px] rounded-[4px] bg-purple2 p-[8px] text-[18px] font-bold text-black">
              Expired
            </div>
          ) : null}
          {projectDetail !== undefined && projectDetail.haveWinner ? (
            <div
              className={`absolute left-[16px] top-[16px] rounded-[4px] p-[8px] text-[18px] font-bold ${
                projectDetail?.winner.vec[0] === address ? 'linear-border' : 'bg-black'
              }`}
            >
              {projectDetail?.winner.vec[0] === address ? 'YOU WIN' : 'ENDED'}
            </div>
          ) : null}
          <div className="h-[376px] w-[376px] rounded-[8px]">
            {projectDetail !== undefined ? (
              <img
                src={projectDetail.type === 'token' ? projectDetail.url : coin}
                className="h-full w-full rounded-[8px]"
              />
            ) : null}
          </div>
        </div>
        <div>
          <div className="text-[36px] font-semibold">{projectDetail?.description}</div>
          <div className="mt-[24px] text-[12px] font-medium text-gray2">
            <span>*Winners will be drawn immediately when all tickets are sold. </span>
            <Popover
              content={
                <div className="w-[666px] rounded-[16px] bg-black px-[24px] pb-[50px] pt-[24px] font-inter text-[15px] text-white">
                  <div className="flex items-center gap-[16px]">
                    <img src={dashboard} />
                    <div className="text-[20px] font-bold">Algorithm Guareented</div>
                  </div>
                  <div className="mt-[40px] text-gray2">
                    The algorithm of generate lucky code is following
                  </div>
                  <div className="my-[20px] h-[1px] w-full bg-gray" />
                  <div>- The seed of random number is consist of :</div>
                  <div>1.address of the last transaction's sender</div>
                  <div>2.a counter in module</div>
                  <div>3.current block height</div>
                  <div>4.current time stamp</div>
                  <div>5.hash of the script being executed</div>
                  <div>6.current sequence number of the sender</div>
                  <div className="mt-[20px] font-semibold">
                    These data will be serialized to bytes and joined together，lucky code is
                    generated by calculate the hash of this bytes mod the sum of tickets.
                  </div>
                </div>
              }
              overlayClassName="rounded-[16px]"
              placement="bottom"
            >
              <span className="cursor-pointer text-white underline">Algorithm Guareented</span>
            </Popover>
          </div>
          <div className="mt-[16px] flex items-center">
            <Trophy className="text-purple" />
            <div className="ml-[12px] mr-[32px] w-[100px] text-[14px] font-medium text-gray2">
              Value
            </div>
            <div>
              {projectDetail?.type === 'coin'
                ? `${new BigNumber(projectDetail?.reward_val ?? 0)
                    .shiftedBy(Precision[chain])
                    .toFormat()} ${TokenName[chain]}`
                : `${projectDetail?.reward_val ?? 0} NFT`}
            </div>
          </div>
          <div className="mt-[20px] flex items-center">
            <Ticket className="w-[20px] text-purple" />
            <div className="ml-[12px] mr-[32px] w-[100px] text-[14px] font-medium text-gray2">
              Per Ticket
            </div>
            <div>
              {new BigNumber(projectDetail?.ticket_value ?? 0)
                .shiftedBy(Precision[chain])
                .toFormat()}{' '}
              {TokenName[chain]}
            </div>
          </div>
          <div className="mt-[20px] flex items-center">
            <img src={scoreboard} />
            <div className="ml-[12px] mr-[32px] w-[100px] shrink-0 text-[14px] font-medium text-gray2">
              Progress
            </div>
            <div className="relative h-[12px] w-[220px] rounded-[2px] bg-gray">
              <div
                className={`absolute left-0 top-0 bottom-0 w-[var(--progress)] rounded-[2px] bg-purple`}
                style={{ '--progress': progress } as CSSProperties}
              />
            </div>
            <div className="ml-[10px] rounded-[6px] bg-gray px-[7px] py-[2px] font-inter text-[15px] font-semibold">
              {projectDetail?.selled_tickets ?? 0}/{projectDetail?.total_tickets ?? 0}
            </div>
          </div>
          {projectDetail !== undefined && !projectDetail.isExpired && !projectDetail.haveWinner ? (
            <div>
              <div className="mt-[24px] flex items-center gap-[10px]">
                <button
                  className="select-none rounded-[5px] bg-gray p-[8px] text-[16px] font-medium"
                  onClick={() =>
                    handleChange(
                      projectDetail === undefined
                        ? 1
                        : new BigNumber(projectDetail.total_tickets)
                            .minus(projectDetail.selled_tickets)
                            .toNumber(),
                    )
                  }
                >
                  Max
                </button>
                <div className="flex items-center gap-[15px] rounded-full bg-gray">
                  <div
                    className="cursor-pointer p-[15px] pr-0"
                    onClick={ticketAmount === 1 ? undefined : () => handleChange(ticketAmount - 1)}
                  >
                    <ArrowLeft
                      className={`text-white ${
                        ticketAmount === 1 ? 'cursor-not-allowed !text-gray2' : ''
                      }`}
                    />
                  </div>
                  <InputNumber
                    className="w-[85px] select-none bg-transparent text-center text-white"
                    bordered={false}
                    min={1}
                    max={
                      projectDetail === undefined
                        ? 1
                        : new BigNumber(projectDetail.total_tickets)
                            .minus(projectDetail.selled_tickets)
                            .toNumber()
                    }
                    controls={false}
                    value={ticketAmount}
                    onChange={handleChange}
                  />
                  <div
                    className="cursor-pointer p-[15px] pl-0"
                    onClick={
                      projectDetail === undefined ||
                      ticketAmount ===
                        new BigNumber(projectDetail.total_tickets)
                          .minus(projectDetail.selled_tickets)
                          .toNumber()
                        ? undefined
                        : () => handleChange(ticketAmount + 1)
                    }
                  >
                    <ArrowLeft
                      className={`rotate-180 text-white ${
                        projectDetail === undefined ||
                        ticketAmount ===
                          new BigNumber(projectDetail.total_tickets)
                            .minus(projectDetail.selled_tickets)
                            .toNumber()
                          ? 'cursor-not-allowed !text-gray2'
                          : ''
                      }`}
                    />
                  </div>
                </div>
                <div className="select-none text-[20px] font-medium">tickets</div>
                <div className="h-[40px] w-[2px] bg-gray" />
                <div>
                  <div className="text-[14px] font-medium text-gray2">Total</div>
                  <div className="text-[24px] font-medium">
                    {`${new BigNumber(projectDetail?.ticket_value ?? 0)
                      .shiftedBy(Precision[chain])
                      .times(ticketAmount)
                      .toFormat()} ${TokenName[chain]}`}
                  </div>
                </div>
              </div>
              <div className="inline-block">
                <button
                  className={`mt-[16px] rounded-full bg-green px-[118px] py-[16px] text-[16px] font-bold ${
                    requestStatus === 'pending' ? 'cursor-not-allowed !bg-gray2' : ''
                  }`}
                  onClick={
                    address !== ''
                      ? requestStatus === 'pending'
                        ? undefined
                        : buyTickets
                      : () => dispatch(setConnectWalletVisible(true))
                  }
                >
                  {address !== '' ? 'Buy Now' : 'Connect Wallet'}
                </button>
                <div className="mt-[5px] flex items-center justify-between text-[12px] font-medium text-gray2">
                  <div>wallet balance</div>
                  <div>{`${balance.toFormat()} ${TOKEN_NAME[projectDetail.fund_type]}`}</div>
                </div>
              </div>
            </div>
          ) : null}
          {projectDetail !== undefined && projectDetail.isExpired && !projectDetail.haveWinner ? (
            <div>
              <div className="mt-[24px] text-[14px] font-medium text-gray2">My Investment</div>
              <div className="mt-[5px] text-[24px] font-medium">
                {`${new BigNumber(projectDetail?.ticket_value ?? 0)
                  .shiftedBy(Precision[chain])
                  .times(tickets.length)
                  .toFormat()} ${TokenName[chain]}`}
              </div>
              <button
                className={`mt-[16px] rounded-full bg-purple px-[40px] py-[16px] text-[16px] font-bold ${
                  (address !== '' && tickets[0]?.state !== '0') || requestStatus === 'pending'
                    ? 'cursor-not-allowed !bg-gray2'
                    : ''
                }`}
                onClick={
                  address !== ''
                    ? tickets[0]?.state !== '0' || requestStatus === 'pending'
                      ? undefined
                      : claim
                    : () => dispatch(setConnectWalletVisible(true))
                }
              >
                {address !== ''
                  ? tickets[0]?.state !== '0'
                    ? 'Claimed'
                    : 'Claim My Investment'
                  : 'Connect Wallet'}
              </button>
            </div>
          ) : null}
          {projectDetail !== undefined && projectDetail.haveWinner ? (
            <div>
              <div className="mt-[20px] flex items-center">
                <Trophy className="text-yellow" />
                <div className="ml-[12px] mr-[32px] w-[100px] text-[14px] font-medium text-gray2">
                  Winner
                </div>
                <div className="linear-border rounded-[4px] p-[2px]">
                  <div className="relative rounded-[4px] bg-black2 px-[8px] py-[4px] text-[12px]">
                    {hideAddress(projectDetail.winner.vec[0])}
                  </div>
                </div>
                <img src={winnerIcon} className="relative bottom-[5px] ml-[10px]" />
              </div>
              <div className="mt-[20px] flex items-center">
                <Ticket className="w-[20px] text-yellow" />
                <div className="ml-[12px] mr-[32px] w-[100px] text-[14px] font-medium text-gray2">
                  Winner Ticket
                </div>
                <div className="linear-border rounded-[4px] p-[2px]">
                  <div className="relative rounded-[4px] bg-black2 px-[8px] py-[4px] text-[12px]">
                    {projectDetail.lucky_code.vec[0]}
                  </div>
                </div>
              </div>
              {projectDetail.winner.vec[0] === address ? (
                <button
                  className={`linear-border mt-[40px] rounded-full px-[24px] py-[16px] text-[16px] font-bold ${
                    (address !== '' && projectDetail.is_claimed === true) ||
                    requestStatus === 'pending'
                      ? 'cursor-not-allowed !bg-gray2 bg-none'
                      : ''
                  }`}
                  onClick={
                    address !== ''
                      ? projectDetail.is_claimed === true || requestStatus === 'pending'
                        ? undefined
                        : claim
                      : () => dispatch(setConnectWalletVisible(true))
                  }
                >
                  {address !== ''
                    ? projectDetail.is_claimed === true
                      ? 'Claimed'
                      : 'Claim Rewards'
                    : 'Connect Wallet'}
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {tickets.length > 0 ? (
        <div>
          <div className="ml-[40px] mt-[40px] mb-[16px] flex items-center gap-[5px]">
            <Receipt className="text-purple" />
            <div className="text-[24px] font-bold">My Tickets</div>
            {projectDetail?.isLive === true ? (
              <button
                className="ml-[20px] rounded-[12px] border-[2px] px-[16px] py-[4px] text-[15px] font-bold"
                onClick={() => setTransferModalOpen(true)}
              >
                Send
              </button>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-[16px] px-[40px]">
            {tickets.map((v: any) => (
              <div
                key={v.ticket_code}
                className={`rounded-[16px] bg-black px-[16px] py-[8px] text-[16px] font-semibold ${
                  projectDetail?.lucky_code.vec[0] === v.ticket_code ? 'linear-border' : ''
                }`}
              >
                {v.ticket_code}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="ml-[40px] mt-[40px] mb-[16px] flex items-center gap-[5px]">
        <img src={notes} />
        <div className="text-[24px] font-bold">Participated Records</div>
      </div>
      <div className="hide-scrollbar h-[232px] rounded-[12px] bg-black py-[24px]">
        {records.map((v, i) => (
          <div
            key={i}
            className="flex cursor-pointer items-center justify-between py-[8px] px-[40px] duration-300 hover:bg-gray"
            onClick={() =>
              window.open(
                chain === ChainName.apots
                  ? `https://explorer.aptoslabs.com/account/${v.account}`
                  : `https://explorer.sui.io/transaction/${v.account}?network=testnet`,
              )
            }
          >
            <div>{hideAddress(v.account)}</div>
            <div>{v.amount} Tickets</div>
            <div className="flex items-center">
              <span>
                {`${moment(v.timestamp * 1000)
                  .utc()
                  .format('D MMM, YYYY hh:mm:ss')}`}
              </span>
            </div>
            <img src={open} />
          </div>
        ))}
      </div>
      <div className="ml-[40px] mt-[40px] mb-[16px] flex items-center gap-[5px]">
        <img src={fire} />
        <div className="text-[24px] font-bold">Related Activities</div>
      </div>
      {projectList.length > 0 ? (
        <div className="flex rounded-[12px] bg-black p-[40px]">
          <div className="flex flex-1 justify-center">
            <div className="flex w-full max-w-[425px] flex-col">
              <div
                className="relative flex h-[425px] cursor-pointer items-center justify-center"
                onClick={() => navigate(`/${chain}/detail/${projectList[0].key}`)}
              >
                <img
                  src={projectList[0].type === 'token' ? projectList[0].url : coin}
                  className="w-full rounded-[16px]"
                />
                <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black text-[32px] font-semibold opacity-0 duration-300 hover:opacity-80">
                  Buy Now
                </div>
              </div>
              <div className="my-[24px] flex items-start justify-between">
                <div>
                  <div className="text-[24px] font-semibold">{projectList[0].description}</div>
                </div>
                <div className="rounded-[4px] border-[2px] border-yellow p-[8px] text-[12px] font-bold text-yellow">
                  {projectList[0].type === 'coin'
                    ? `${new BigNumber(projectList[0].reward_val)
                        .shiftedBy(Precision[chain])
                        .toFormat()} ${TokenName[chain]}`
                    : `${projectList[0].reward_val} NFT`}
                </div>
              </div>
              <div className="mt-auto flex items-center gap-[30px]">
                <div className="rounded-[6px] bg-gray px-[7px] py-[2px] font-inter text-[15px] font-semibold">
                  {`${projectList[0].selled_tickets}/${projectList[0].total_tickets}`}
                </div>
                <div className="relative h-[12px] flex-1 rounded-[2px] bg-gray">
                  <div
                    className={`linear-bg absolute left-0 top-0 bottom-0 w-[var(--progress)] rounded-[2px]`}
                    style={
                      {
                        '--progress': `${new BigNumber(projectList[0].selled_tickets)
                          .div(projectList[0].total_tickets)
                          .times(100)
                          .toString()}%`,
                      } as CSSProperties
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ml-[40px] flex flex-1 flex-col items-center">
            {projectList
              .filter((project, i) => i > 0 && i < 4)
              .map((project, i) => (
                <div key={i} className="flex gap-[24px] [&:nth-child(n+2)]:mt-[24px]">
                  <div
                    className="relative cursor-pointer"
                    onClick={() => navigate(`/${chain}/detail/${project.key}`)}
                  >
                    <img
                      src={project?.type === 'token' ? project.url : coin}
                      className="h-[164px] w-[164px] rounded-[8px]"
                    />
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black text-[16px] font-semibold opacity-0 duration-300 hover:opacity-80">
                      Buy Now
                    </div>
                  </div>
                  <div className="flex w-[216px] flex-1 flex-col items-start">
                    <div className="text-[12px] text-gray2">{project.description}</div>
                    <div className="mt-[5px] rounded-[4px] bg-green p-[8px] text-[12px] font-bold">
                      {project.type === 'coin'
                        ? `${new BigNumber(project.reward_val)
                            .shiftedBy(Precision[chain])
                            .toFormat()} ${TokenName[chain]}`
                        : `${project.reward_val} NFT`}
                    </div>
                    <div className="mt-auto flex gap-[5px]">
                      <img src={group} />
                      <div className="text-[15px] font-semibold">{`${project.selled_tickets}/${project.total_tickets}`}</div>
                    </div>
                    <div className="relative mt-[5px] h-[12px] w-full rounded-[2px] bg-gray">
                      <div
                        className={`linear-bg absolute left-0 top-0 bottom-0 w-[var(--progress)] rounded-[2px]`}
                        style={
                          {
                            '--progress': `${new BigNumber(project.selled_tickets)
                              .div(project.total_tickets)
                              .times(100)
                              .toString()}%`,
                          } as CSSProperties
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : null}

      <Modal
        width={560}
        footer={null}
        zIndex={9999}
        open={isModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        maskClosable={requestStatus === 'pending' ? false : true}
        onCancel={() => setModalStatus({ isModalOpen: false })}
      >
        <div className="relative rounded-[16px] bg-black2 text-center">
          {requestStatus === 'success' && type === 'buySuccess' ? (
            <img src="/common/success.svg" className="w-full" />
          ) : null}
          <div
            className={`inline-block w-[400px] ${
              requestStatus === 'success' && type === 'buySuccess'
                ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                : ''
            }`}
          >
            {type !== 'buySuccess' ? (
              <img
                src={
                  requestStatus === 'pending'
                    ? '/common/pending.svg'
                    : requestStatus === 'success'
                    ? '/common/check.svg'
                    : '/common/fail.svg'
                }
                className={`mx-auto mt-[80px] ${requestStatus === 'pending' && 'animate-spin'}`}
              />
            ) : null}
            <div className="mt-[20px] text-[20px] font-semibold text-white">
              {requestStatus === 'pending'
                ? 'Wait for Confirmation !'
                : requestStatus === 'success'
                ? 'Succeeded !'
                : 'Failed !'}
            </div>
            <div className="mt-[8px] text-[48px] font-semibold text-white">{requestValue}</div>
            <div className="mt-[16px] mb-[50px] text-[15px] font-semibold text-gray2">{info}</div>
            {requestStatus === 'success' && type === 'buySuccess' ? (
              <button
                className="rounded-[12px] bg-purple px-[20px] py-[12px] font-inter text-[15px] font-bold text-white"
                onClick={() => setModalStatus({ isModalOpen: false })}
              >
                Good Luck
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
      <Modal
        width={560}
        footer={null}
        open={winnerModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        onCancel={() => setWinnerModalOpen(false)}
      >
        {address !== '' && projectDetail?.winner.vec[0] === address ? (
          <div className="rounded-[12px] bg-black2 p-[24px] text-center">
            <div className="mx-auto mt-[60px] h-[128px] w-[128px] text-center">
              <Spline
                scene="https://prod.spline.design/oZTTT4sh7pG-4vOg/scene.splinecode"
                className="pointer-events-none"
              />
            </div>
            <div className="mt-[20px] text-[20px] font-semibold text-white">Congratulations !</div>
            <div className="mt-[8px] text-[48px] font-semibold text-white">
              {projectDetail?.type === 'coin'
                ? `${new BigNumber(projectDetail?.reward_val ?? 0)
                    .shiftedBy(Precision[chain])
                    .toFormat()} ${TokenName[chain]}`
                : `${projectDetail?.reward_val ?? 0} NFT`}
            </div>
            <div className="mt-[16px] text-[15px] font-semibold text-gray2">
              You won {projectDetail?.description} !
            </div>
          </div>
        ) : (
          <div className="rounded-[12px] bg-black2 p-[24px] text-center">
            <div className="mx-auto mt-[50px]">
              <img src={block} className="mx-auto animate-bounce" />
            </div>
            <div className="mt-[20px] text-[20px] font-semibold text-white">
              Sorry, you did not win.
            </div>
            <div className="mt-[16px] flex items-center justify-center gap-[10px] text-[15px] font-semibold text-gray2">
              <div>Winner Ticket</div>
              <div className="linear-border min-w-[60px] rounded-[12px] p-[2px]">
                <div className="relative rounded-[12px] bg-black2 px-[8px] py-[4px] text-[12px] text-white">
                  {projectDetail?.lucky_code.vec[0]}
                </div>
              </div>
            </div>
            <button
              className="mt-[20px] mb-[30px] rounded-[12px] bg-white px-[20px] py-[12px] font-inter text-[15px] font-bold text-purple"
              onClick={() => setWinnerModalOpen(false)}
            >
              Try My Luck Again
            </button>
          </div>
        )}
      </Modal>
      <Modal
        width={560}
        footer={null}
        open={transferModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        onCancel={() => setTransferModalOpen(false)}
      >
        <div className="p-[24px]">
          <div className="flex items-center gap-[16px]">
            <img src={gift} />
            <div className="text-[20px] font-semibold text-white">Send Tickets</div>
          </div>
          <div className="mt-[50px] max-h-[600px]">
            <div className="hide-scrollbar grid max-h-[525px] grid-cols-2 gap-[10px]">
              {tickets.map((ticket: any, i: number) => (
                <div
                  key={i}
                  className="cursor-pointer rounded-[8px] border-[1px] border-gray2 py-[12px] text-center duration-300 hover:bg-gray"
                  onClick={() =>
                    setInfoModal({ isInfoModalOpen: true, ticketId: ticket.ticket_code })
                  }
                >
                  <div className="text-[15px] font-bold text-white">
                    Ticket #{ticket.ticket_code}
                  </div>
                  <div className="mt-[4px] text-[13px] font-semibold text-gray2">
                    {`${new BigNumber(projectDetail?.ticket_value ?? 0)
                      .shiftedBy(Precision[chain])
                      .toFormat()} ${TokenName[chain]}`}
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-[25px] flex justify-end">
              <button
                className="h-[50px] w-[150px] rounded-[12px] bg-purple"
                onClick={
                  address !== ''
                    ? () => setInfoModal({ isInfoModalOpen: true, ticketId: 'all' })
                    : () => dispatch(setConnectWalletVisible(true))
                }
              >
                {address !== '' ? 'Send All' : 'Connect Wallet'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={560}
        footer={null}
        open={isInfoModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        onCancel={() => setInfoModal({ isInfoModalOpen: false })}
      >
        <div className="bg-black2 p-[24px] text-white">
          <div className="flex items-center gap-[16px]">
            <img src={gift} />
            <div className="text-[20px] font-semibold">Send Tickets</div>
          </div>
          <div>
            <div className="mt-[40px] mb-[12px] flex items-center gap-[5px]">
              <div className="text-[15px] font-medium">
                {`${projectDetail?.description} (${ticketId === 'all' ? tickets.length : 1})`}
              </div>
            </div>
            <div className="mb-[12px] h-[1px] w-full bg-gray" />
            <div className="flex flex-wrap gap-[16px]">
              {ticketId === 'all' ? (
                tickets.map((v: any) => (
                  <div
                    key={v.ticket_code}
                    className="rounded-[16px] bg-black px-[16px] py-[8px] text-[16px] font-semibold"
                  >
                    {v.ticket_code}
                  </div>
                ))
              ) : (
                <div className="rounded-[16px] bg-black px-[16px] py-[8px] text-[16px] font-semibold">
                  {ticketId}
                </div>
              )}
            </div>
          </div>
          <div className="mt-[40px] flex items-center gap-[40px]">
            <div className="shrink-0 text-[18px] font-semibold text-gray2">Send to</div>
            <div
              className={`relative w-full flex-1 rounded-[5px] border-[1px] border-transparent ${
                isAddressInvalid ? 'border-[1px] !border-red' : ''
              }`}
            >
              <Input
                onBlur={() => {
                  if (
                    receiver === ''
                    // || !new RegExp('^0x[0-9a-fA-F]{64}$', 'gim').test(receiver)
                  ) {
                    setIsAddressInvalid(true);
                  } else {
                    setIsAddressInvalid(false);
                  }
                }}
                bordered={false}
                className="select-none rounded-[5px] !bg-gray !text-left text-white"
                value={receiver}
                onChange={e => {
                  if (
                    e.target.value === ''
                    // || !new RegExp('^0x[0-9a-fA-F]{64}$', 'gim').test(e.target.value)
                  ) {
                    setIsAddressInvalid(true);
                  } else {
                    setIsAddressInvalid(false);
                  }
                  setReceiver(e.target.value);
                }}
              />
              {isAddressInvalid ? (
                <div className="absolute top-[100%] mt-[5px] text-red">Invalid address</div>
              ) : null}
            </div>
          </div>
          <div className="mt-[30px] h-[1px] w-full bg-gray" />
          <div className="mt-[40px] flex gap-[8px]">
            <button
              className="h-[50px] w-[150px] flex-1 rounded-[12px] border-[2px] border-gray text-[15px] font-bold"
              onClick={() => setInfoModal({ isInfoModalOpen: false })}
            >
              Cancel
            </button>
            <button
              className={`h-[50px] w-[150px] flex-1 rounded-[12px] border-[2px] border-purple bg-purple text-[15px] font-bold ${
                isAddressInvalid && address !== ''
                  ? 'cursor-not-allowed !border-gray2 bg-gray2'
                  : ''
              }`}
              onClick={
                address !== ''
                  ? isAddressInvalid
                    ? undefined
                    : () => send()
                  : () => dispatch(setConnectWalletVisible(true))
              }
            >
              {address !== '' ? 'Send Now' : 'Connect Wallet'}
            </button>
          </div>
        </div>
      </Modal>
    </PageContainer>
  );
};
