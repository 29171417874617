import BigNumber from 'bignumber.js';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import block from 'assets/images/common/block.svg';
import coin from 'assets/images/common/nft4.png';
import { PageContainer } from 'components/PageContainer';
import { useAppSelector } from 'store/hooks';
import { getWinnerList } from 'utils/backendApi';
import { Precision } from 'utils/enums';
import { hideAddress } from 'utils/formatters';
import { WinnerInfo } from 'utils/models';

export const Prizes: FC = () => {
  const navigate = useNavigate();

  const { chain } = useAppSelector(state => state.projectsSlice);

  const [winnerList, setWinnerList] = useState<WinnerInfo[]>([]);

  useEffect(() => {
    (async () => {
      const winnerList = await getWinnerList();
      if (winnerList !== undefined) {
        setWinnerList(winnerList);
      }
    })();
  }, []);

  return (
    <PageContainer className="font-inter">
      <div className="flex h-[72px] items-center">
        <div className="text-[24px] font-semibold">Prizes</div>
      </div>
      <div className="mt-[32px] flex text-[13px] font-bold text-gray2">
        <div className="flex-[2]">Market</div>
        <div className="flex-[1]">Ticket Number</div>
        <div className="flex-[1]">Winner</div>
        <div className="flex-[1]">Time</div>
      </div>
      <div className="mt-[24px] mb-[12px] h-[1px] w-full bg-gray" />
      <div className="hide-scrollbar h-[600px]">
        {winnerList.length > 0 ? (
          winnerList.map((winnerInfo, i: number) => (
            <div
              key={i}
              className="flex h-[104px] cursor-pointer items-center duration-300 hover:bg-gray"
              onClick={() => navigate(`/${chain}/detail/${winnerInfo.box}`)}
            >
              <div className="flex flex-[2] items-center gap-[10px]">
                <img
                  src={winnerInfo.url === null ? coin : winnerInfo.url}
                  className="h-[80px] w-[80px] rounded-[8px]"
                />
                <div>
                  <div className="text-[15px] font-bold">{winnerInfo.name}</div>
                  <div className="mt-[4px] text-[13px] font-semibold text-gray2">
                    {`Value ${
                      winnerInfo.type === 'NFT'
                        ? winnerInfo.amount
                        : new BigNumber(winnerInfo.amount).shiftedBy(Precision[chain]).toFormat()
                    } ${winnerInfo.type}`}
                  </div>
                </div>
              </div>
              <div className="flex-[1] text-[14px] font-semibold text-gray2">
                #{winnerInfo.ticket}
              </div>

              <div className="flex-[1] text-[14px] font-semibold text-gray2">
                <div className="linear-border inline-block rounded-[4px] p-[2px]">
                  <div
                    className="relative rounded-[4px] bg-black2 px-[8px] py-[4px] text-[12px] text-white"
                    onClick={e => {
                      e.stopPropagation();
                      window.open(`https://explorer.aptoslabs.com/account/${winnerInfo.winner}`);
                    }}
                  >
                    {hideAddress(winnerInfo.winner)}
                  </div>
                </div>
              </div>
              <div className="flex-[1] text-[14px] font-semibold text-gray2">{`${moment(
                parseInt(winnerInfo.time) * 1000,
              )
                .utc()
                .format('D MMM, YYYY hh:mm:ss')}`}</div>
            </div>
          ))
        ) : (
          <div className="mt-[100px] text-center">
            <img src={block} className="mx-auto animate-bounce" />
            <div className="mt-[50px]">
              <span className="text-[12px] font-medium text-gray2">
                You haven't participated in any activitie yet.
              </span>
              <span
                className="linear-text ml-[10px] cursor-pointer text-[12px] font-semibold underline decoration-gray2 underline-offset-4"
                onClick={() => navigate(`/${chain}/home/#market`)}
              >
                Go to Market
              </span>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
