import { CloseOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import Spline from '@splinetool/react-spline';
import { useWallet } from '@suiet/wallet-kit';
import { InputNumber, Modal, Spin } from 'antd';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import numeral from 'numeral';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import coin from 'assets/images/common/nft4.png';
import { ReactComponent as ArrowLeft } from 'assets/images/detail/arrow-left.svg';
import fire from 'assets/images/detail/fire.svg';
import notes from 'assets/images/detail/notes.svg';
import { ReactComponent as Ticket } from 'assets/images/detail/ticket.svg';
import { ReactComponent as Trophy } from 'assets/images/detail/trophy.svg';
import banner from 'assets/images/home/banner.svg';
import { ReactComponent as Ligntning } from 'assets/images/home/lightning.svg';
import { PageContainer } from 'components/PageContainer';
import { useProjectList } from 'hooks/useProjectList';
import { setConnectWalletVisible } from 'store/features/walletStatesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTickets } from 'utils/aptos-sdk';
import { getHomePageData, getProjectList } from 'utils/backendApi';
import { AptosWalletName, ChainName, Precision, TokenName } from 'utils/enums';
import { OverView, ProjectDetailPatch } from 'utils/models';
import { getCoinsObjectIdReduceGas } from 'utils/sui-sdk';
import { getWalletApi } from 'utils/wallets';

export const Index: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const wallet = useWallet();

  const projectList = useProjectList();
  const { address, connectedWallet } = useAppSelector(state => state.walletStatesSlice);
  const { chain, suiConfig } = useAppSelector(state => state.projectsSlice);

  const [overview, setOverview] = useState<OverView>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectDetailPatch>();
  const [ticketAmount, setTicketAmount] = useState(1);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    getOverView();
  }, [chain]);

  useEffect(() => {
    if (selectedProject !== undefined) {
      const leftTicket = new BigNumber(selectedProject.total_tickets)
        .minus(selectedProject.selled_tickets)
        .toNumber();
      setTicketAmount(leftTicket >= 5 ? 5 : leftTicket);
    }
  }, [projectList, selectedProject]);

  useEffect(() => {
    const hash = location.hash;
    if (hash === '#market' && projectList.length >= 1) {
      const element = document.getElementById(hash.substring(1));
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, projectList]);

  const getOverView = async () => {
    const overview = await getHomePageData();
    if (overview !== undefined) {
      setOverview(overview);
    }
  };

  const quickBuy = (project: ProjectDetailPatch) => {
    setIsModalOpen(true);
    setSelectedProject(project);
  };

  const handleChange = (value: number | null) => {
    if (value === null) {
      setTicketAmount(1);
    } else {
      setTicketAmount(value);
    }
  };

  const buyTickets = async () => {
    if (selectedProject === undefined || connectedWallet === null) {
      return;
    }
    if (chain === ChainName.sui && suiConfig === null) {
      return;
    }
    setIsPending(true);
    try {
      if (chain === ChainName.apots) {
        const walletApi = await getWalletApi(connectedWallet as AptosWalletName);
        await walletApi.buyTickets(
          parseInt(selectedProject.key),
          ticketAmount,
          selectedProject.type,
        );
      } else if (chain === ChainName.sui && suiConfig !== null) {
        const objectIds = await getCoinsObjectIdReduceGas(address);
        const res = await wallet.signAndExecuteTransaction({
          transaction: {
            kind: 'moveCall',
            data: {
              packageObjectId: suiConfig.contractAddress,
              module: 'Box',
              function: 'buy_tickets',
              typeArguments: [selectedProject.reward_type as string, selectedProject.fund_type],
              arguments: [
                selectedProject.key,
                suiConfig.objectId.TicketsBag,
                suiConfig.objectId.UsersBag,
                objectIds,
                String(ticketAmount),
              ],
              gasBudget: 5000,
            },
          },
        });
        if (res.effects.status.status === 'failure') {
          throw new Error('Bought failure');
        }
      }
      setIsModalOpen(false);
    } catch (err: any) {
      console.log(err);
    }
    setIsPending(false);
    setTimeout(() => {
      getProjectList();
      if (address !== '') {
        getTickets(address);
      }
    }, 1000);
  };

  return (
    <PageContainer showFooter={true}>
      <img src={banner} className="w-full rounded-[12px]" />
      <div className="mt-[40px] flex items-center gap-[24px] px-[34px]">
        <div className="flex flex-1 items-center gap-[32px] rounded-[12px] py-[48px]">
          <div className="relative flex h-[160px] w-[160px] items-center justify-center rounded-[24px] bg-gradient-to-b from-[#90FFFF] to-[#FFD56A]">
            <div className="absolute left-0 -top-[20px] -right-[40px] -bottom-[20px] z-[100]">
              <Spline
                scene="https://prod.spline.design/oZTTT4sh7pG-4vOg/scene.splinecode"
                className="pointer-events-none"
              />
            </div>
          </div>
          <div>
            <div className="inline-block rounded-[4px] border-[2px] border-yellow p-[6px] text-[12px] font-bold text-yellow">
              DRAW VALUE
            </div>
            <div className="mt-[10px] flex items-baseline gap-[10px] font-inter text-[48px] font-bold">
              <span className="ml-[5px]">
                {numeral(
                  new BigNumber(overview?.drawValue?.['0x1::aptos_coin::AptosCoin'] ?? 0)
                    .shiftedBy(Precision[chain])
                    .toNumber(),
                )
                  .format('0 a')
                  .toUpperCase()}
              </span>
              <span className="font-regular text-[24px]">{TokenName[chain]}</span>
            </div>
            <div className="text-[12px] text-gray2">
              Updated {moment().utc().format('MMM Do, YYYY')}
            </div>
          </div>
        </div>
        <div className="flex flex-1 items-center gap-[32px] rounded-[12px] py-[48px]">
          <div className="relative h-[160px] w-[160px] rounded-[24px] bg-gradient-to-b from-[#FFD56A] to-[#EA43A7]">
            <div className="absolute -left-[15px] -top-[20px] -right-[25px] -bottom-[20px] z-[100]">
              <Spline
                scene="https://prod.spline.design/3MoMGf6hnMDIKlHA/scene.splinecode"
                className="pointer-events-none"
              />
            </div>
          </div>
          <div>
            <div className="linear-border inline-block rounded-[4px] p-[8px] text-[12px] font-bold">
              WINNER
            </div>
            <div className="mt-[10px] font-inter text-[48px] font-bold">
              {numeral(overview?.winnerCount ?? 0)
                .format('0 a')
                .toUpperCase()}
            </div>
            <div className="text-[12px] text-gray2">
              Updated {moment().utc().format('MMM Do, YYYY')}
            </div>
          </div>
        </div>
      </div>
      <div className="my-[40px] flex items-center gap-[5px]">
        <img src={notes} />
        <div className="text-[16px]">0xd46...8ebf3 just bought 10 tickets for</div>
        <div className="text-[16px] font-medium text-purple">1 {TokenName[chain]}</div>
      </div>
      <div className="flex items-center gap-[5px]" id="market">
        <div className="text-[20px] font-semibold">Live</div>
        <img src={fire} />
      </div>
      <div className="mt-[32px] grid grid-cols-home gap-[24px]">
        {projectList.map((project, i: number) => (
          <div key={i} className="buy-now-hover flex flex-col">
            <div className="img-hover w-100% relative bottom-0 h-0 cursor-pointer pt-[100%] duration-300">
              <img
                src={project.type === 'token' ? project.url : coin}
                className="absolute top-0 left-0 right-0 bottom-0 h-full w-full rounded-[12px]"
                onClick={() => navigate(`/${chain}/detail/${project.key}`)}
              />
              {project.haveWinner ? (
                <div
                  className={`absolute left-[16px] top-[16px] rounded-[4px] p-[8px] text-[18px] font-bold ${
                    address !== '' && project.winner.vec[0] === address
                      ? 'linear-border'
                      : 'bg-black'
                  }`}
                >
                  {address !== '' && project.winner.vec[0] === address ? 'YOU WIN' : 'ENDED'}
                </div>
              ) : null}
            </div>
            <div className="mt-[16px] text-[15px] font-medium">{project.description}</div>
            <div className="mt-[8px] flex items-start justify-between">
              <div>
                <div className="text-[10px] font-medium text-gray2">Value</div>
                <div className="text-[22px] font-semibold">
                  {project.type === 'coin'
                    ? `${new BigNumber(project.reward_val)
                        .shiftedBy(Precision[chain])
                        .toFormat()} ${TokenName[chain]}`
                    : `${project.reward_val} NFT`}
                </div>
              </div>
              <div>
                <div className="text-[10px] font-medium text-gray2">Per Ticket</div>
                <div className="text-[22px] font-semibold text-yellow">
                  {new BigNumber(project.ticket_value).shiftedBy(Precision[chain]).toFormat()}{' '}
                  {TokenName[chain]}
                </div>
              </div>
            </div>
            <div className="relative mt-[8px] h-[12px] rounded-[2px] bg-gray">
              <div
                className={`linear-bg absolute left-0 top-0 bottom-0 w-[var(--progress)] rounded-[2px]`}
                style={
                  {
                    '--progress': `${new BigNumber(project.selled_tickets)
                      .div(project.total_tickets)
                      .times(100)
                      .toString()}%`,
                  } as CSSProperties
                }
              />
            </div>
            <button
              className="mt-[16px] flex h-[52px] items-center justify-center gap-[8px] border-t-[1px] border-gray"
              onClick={
                project !== undefined && !project.isExpired && !project.haveWinner
                  ? () => quickBuy(project)
                  : undefined
              }
            >
              <Ligntning
                className={`buy-now-btn-text text-gray2 ${
                  project !== undefined && !project.isExpired && !project.haveWinner
                    ? ''
                    : '!text-gray2'
                }`}
              />
              <div
                className={`buy-now-btn-text absolute overflow-hidden text-[14px] font-semibold opacity-0 ${
                  project !== undefined && !project.isExpired && !project.haveWinner
                    ? ''
                    : 'cursor-not-allowed !text-gray2'
                }`}
              >
                {project !== undefined && !project.isExpired && !project.haveWinner
                  ? 'Instant Buy'
                  : 'Sold Out'}
              </div>
            </button>
          </div>
        ))}
      </div>
      <Modal
        width={560}
        footer={null}
        open={isModalOpen}
        closeIcon={<CloseOutlined className="rounded-full bg-gray p-[8px] text-white" />}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="p-[24px] text-white">
          <div className="flex items-center gap-[16px]">
            <Ticket className="h-[30px] text-purple" />
            <div className="text-[20px] font-semibold">Buy Tickets</div>
          </div>
          <div className="mt-[40px] flex items-start gap-[24px]">
            <img
              src={selectedProject?.type === 'token' ? selectedProject?.url : coin}
              className="h-[160px] w-[160px] rounded-[12px]"
            />
            <div>
              <div className="text-[15px] font-medium">{selectedProject?.description}</div>
              <div className="mt-[16px] flex items-center">
                <Trophy className="text-purple" />
                <div className="ml-[12px] mr-[16px] w-[68px] text-[14px] font-medium text-gray2">
                  Value
                </div>
                <div>
                  {selectedProject?.type === 'coin'
                    ? `${new BigNumber(selectedProject?.reward_val ?? 0)
                        .shiftedBy(Precision[chain])
                        .toFormat()} ${TokenName[chain]}`
                    : `${selectedProject?.reward_val} NFT`}
                </div>
              </div>
              <div className="mt-[16px] flex items-center">
                <Ticket className="w-[20px] text-purple" />
                <div className="ml-[12px] mr-[16px] w-[68px] text-[14px] font-medium text-gray2">
                  Per Ticket
                </div>
                <div>
                  {new BigNumber(selectedProject?.ticket_value ?? 0)
                    .shiftedBy(Precision[chain])
                    .toFormat()}{' '}
                  {TokenName[chain]}
                </div>
              </div>
              {selectedProject !== undefined &&
              !selectedProject.isExpired &&
              !selectedProject.haveWinner ? (
                <div>
                  <div className="mt-[16px] flex items-center gap-[10px]">
                    <button
                      className="rounded-[5px] bg-gray p-[8px] text-[16px] font-medium"
                      onClick={() =>
                        handleChange(
                          selectedProject === undefined
                            ? 1
                            : new BigNumber(selectedProject.total_tickets)
                                .minus(selectedProject.selled_tickets)
                                .toNumber(),
                        )
                      }
                    >
                      Max
                    </button>
                    <div className="flex items-center gap-[15px] rounded-full bg-gray">
                      <div
                        className="cursor-pointer p-[15px] pr-0"
                        onClick={
                          ticketAmount === 1 ? undefined : () => handleChange(ticketAmount - 1)
                        }
                      >
                        <ArrowLeft
                          className={`text-white ${
                            ticketAmount === 1 ? 'cursor-not-allowed !text-gray2' : ''
                          }`}
                        />
                      </div>
                      <InputNumber
                        className="w-[85px] bg-transparent text-center text-white"
                        bordered={false}
                        min={1}
                        max={
                          selectedProject === undefined
                            ? 1
                            : new BigNumber(selectedProject.total_tickets)
                                .minus(selectedProject.selled_tickets)
                                .toNumber()
                        }
                        controls={false}
                        value={ticketAmount}
                        onChange={handleChange}
                      />
                      <div
                        className="cursor-pointer p-[15px] pl-0"
                        onClick={
                          selectedProject === undefined ||
                          ticketAmount ===
                            new BigNumber(selectedProject.total_tickets)
                              .minus(selectedProject.selled_tickets)
                              .toNumber()
                            ? undefined
                            : () => handleChange(ticketAmount + 1)
                        }
                      >
                        <ArrowLeft
                          className={`rotate-180 text-white ${
                            selectedProject === undefined ||
                            ticketAmount ===
                              new BigNumber(selectedProject.total_tickets)
                                .minus(selectedProject.selled_tickets)
                                .toNumber()
                              ? 'cursor-not-allowed !text-gray2'
                              : ''
                          }`}
                        />
                      </div>
                    </div>
                    <div className="text-[20px] font-medium">tickets</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-[40px] flex justify-between border-b-[1px] border-gray pb-[16px]">
            <div className="text-[15px] font-semibold text-gray2">Total</div>
            <div className="text-[15px] font-semibold">
              {`${new BigNumber(selectedProject?.ticket_value ?? 0)
                .shiftedBy(Precision[chain])
                .times(ticketAmount)
                .toFormat()} ${TokenName[chain]}`}
            </div>
          </div>
          <div className="mt-[40px] flex justify-between gap-[16px]">
            <button
              className="flex-1 rounded-[12px] border-[2px] border-gray py-[12px] font-inter text-[15px] font-bold"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className={`flex-1 rounded-[12px] border-[2px] border-green bg-green py-[12px] font-inter text-[15px] font-bold ${
                isPending ? 'cursor-not-allowed !border-gray2 !bg-gray2' : ''
              }`}
              onClick={
                address === ''
                  ? () => dispatch(setConnectWalletVisible(true))
                  : isPending
                  ? undefined
                  : buyTickets
              }
            >
              <div className="relative inline-block">
                <span>{address === '' ? 'Connect' : 'Buy'}</span>
                {isPending ? (
                  <Spin
                    className="absolute -right-[40px]"
                    indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FFD56A' }} spin />}
                  />
                ) : null}
              </div>
            </button>
          </div>
        </div>
      </Modal>
    </PageContainer>
  );
};
