import { AptosWalletName } from 'utils/enums';
import { Wallet } from 'utils/models';

const WalletModules = {
  [AptosWalletName.Martian]: () => import('./martian'),
  [AptosWalletName.Petra]: () => import('./petra'),
};

const initWallets = (): void => {
  Object.values(AptosWalletName).forEach(async (name: AptosWalletName) => {
    WalletModules[name]().then(res => {
      res.wallet.initDapi();
    });
  });
};

const getWalletApi = async (walletName: AptosWalletName): Promise<Wallet> => {
  const walletApi = await WalletModules[walletName]();
  return walletApi.wallet;
};

export { initWallets, getWalletApi };
