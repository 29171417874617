export enum AptosWalletName {
  Martian = 'Martian',
  Petra = 'Petra',
}

export enum SuiWalletName {
  Sui = 'Sui',
  Suiet = 'Suiet',
  Martian = 'Martian',
}

export enum Token {
  APT = '0x1::aptos_coin::AptosCoin',
  SUI = '0x2::sui::SUI',
}

export enum ChainName {
  sui = 'sui',
  apots = 'aptos',
}

export enum TokenName {
  aptos = 'APT',
  sui = 'SUI',
}

export enum Precision {
  aptos = -8,
  sui = -9,
}
