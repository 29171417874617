import { AptosClient, HexString } from 'aptos';
import axios from 'axios';
import { store } from 'store';
import { updateTickets } from 'store/features/projectsSlice';
import { APTOS_NODE_URL, BACK_END_URL, CONTRACT_ADDRESS_APTOS } from 'utils/config';
import { ChainName } from './enums';

export const aptosClient = new AptosClient(APTOS_NODE_URL);

export const getTickets = async (address: string): Promise<any> => {
  try {
    if (store.getState().projectsSlice.chain === ChainName.sui) {
      axios
        .post(`${BACK_END_URL}/sui/ticket/userTickets`, {
          account: address,
        })
        .then(response => {
          if (response.status === 200) {
            const data: any = {};
            response.data.tickets.forEach((v: any) => {
              data[v.key] = v.value.tickets;
            });
            store.dispatch(updateTickets(data));
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const result: any = await aptosClient.getAccountResource(
        new HexString(address),
        `${CONTRACT_ADDRESS_APTOS}::onedollar_v0::OneDollarTicketsCollection`,
      );
      const data: any = {};
      result.data.onedollar_tickets_map.data.forEach((v: any) => {
        data[v.key] = v.value.tickets;
      });
      store.dispatch(updateTickets(data));
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const getBalance = async (address: string, resourceType: string): Promise<string> => {
  try {
    const result: any = await aptosClient.getAccountResource(
      new HexString(address),
      `0x1::coin::CoinStore<${resourceType}>`,
    );
    return result.data.coin.value;
  } catch (err: any) {
    console.log(err);
    return '0';
  }
};
