import { ReactComponent as MartianLogo } from 'assets/images/wallet/martian.svg';
import { ReactComponent as PetraLogo } from 'assets/images/wallet/petra.svg';
import { AptosWalletName, Token } from './enums';
import { WalletInfo } from './models';

export const APTOS_NODE_URL = 'https://fullnode.devnet.aptoslabs.com/v1';
export const SUI_NODE_URL = 'https://fullnode.testnet.sui.io:443';
export const BACK_END_URL = 'http://192.168.3.13:3000/api/v1/1Dollar';

export const CONTRACT_ADDRESS_APTOS =
  '0x1437fd4c46bac7a9407a91dfe572b55b1b73a4fbfde5e2511ba59aa591e31861'; // 0x9c0a695906d702d0d9548326586dfe89e8c23d1c291f3369620801318a24a3e9

export const WALLET_INFOS: Record<AptosWalletName, WalletInfo> = {
  [AptosWalletName.Martian]: {
    name: AptosWalletName.Martian,
    logo: MartianLogo,
    downloadUrl: 'https://www.martianwallet.xyz/',
  },
  [AptosWalletName.Petra]: {
    name: AptosWalletName.Petra,
    logo: PetraLogo,
    downloadUrl: 'https://petra.app/',
  },
};

export const TOKEN_NAME = {
  [Token.APT]: 'APT',
  [Token.SUI]: 'SUI',
};
