import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { getProjectList } from 'utils/backendApi';
import { ProjectDetailPatch } from 'utils/models';

export const useProjectList = (): ProjectDetailPatch[] => {
  const projectList = useAppSelector(state => state.projectsSlice.projectList);
  const { chain } = useAppSelector(state => state.projectsSlice);

  useEffect(() => {
    getProjectList();
  }, [chain]);

  const res = useMemo(() => {
    return projectList.map(project => {
      const isExpired = new BigNumber(project.create_time)
        .plus(project.delay_time)
        .plus(project.selling_time)
        .times(1000)
        .isLessThan(Date.now());
      const haveWinner = project.winner.vec.length > 0;
      return {
        ...project,
        haveWinner,
        isExpired: isExpired && !haveWinner,
        isLive: !isExpired && !haveWinner,
      };
    });
  }, [projectList]);

  return res;
};
