import { useWallet } from '@suiet/wallet-kit';
import { Popover } from 'antd';
import { FC, useEffect } from 'react';
import { setConnectWalletVisible, updateConnectStatus } from 'store/features/walletStatesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { WALLET_INFOS } from 'utils/config';
import { AptosWalletName, ChainName, SuiWalletName } from 'utils/enums';
import { hideAddress } from 'utils/formatters';
import { getWalletApi } from 'utils/wallets';

interface Props {
  className: string;
}

const Wallet: FC<Props> = ({ className }) => {
  const { connected, select, configuredWallets, address = '', disconnect, name = '' } = useWallet();
  const walletStates = useAppSelector(state => state.walletStatesSlice);
  const { chain } = useAppSelector(state => state.projectsSlice);

  const dispatch = useAppDispatch();

  // update sui wallet status
  useEffect(() => {
    if (connected) {
      const connectedWallet = name.split(' ')[0] as SuiWalletName;
      localStorage.setItem('LAST_CONNECTED_WALLET_NAME', connectedWallet);
      dispatch(
        updateConnectStatus({
          connectedWallet,
          address,
        }),
      );
    } else {
      localStorage.removeItem('LAST_CONNECTED_WALLET_NAME');
      dispatch(updateConnectStatus({ connectedWallet: null, address: '' }));
    }
  }, [connected, address, dispatch, name]);

  const connectApt = async (name: AptosWalletName) => {
    if (!walletStates[name].installed) {
      window.open(WALLET_INFOS[name].downloadUrl);
      return;
    }
    try {
      const walletApi = await getWalletApi(name);
      await walletApi.connect();
    } catch (err: any) {
      console.log(err);
    }
  };

  const disconnectApt = async (name: AptosWalletName) => {
    try {
      const walletApi = await getWalletApi(name);
      await walletApi.disconnect();
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Popover
      key="unconnected"
      overlayClassName="wallets-popover"
      placement="bottom"
      open={walletStates.connectWalletVisible}
      onOpenChange={open => dispatch(setConnectWalletVisible(open))}
      content={
        walletStates.address === '' ? (
          <div className="w-[400px] bg-black p-[32px] text-white">
            <div className="text-[16px] font-semibold">Connect Your Wallet</div>
            {chain === ChainName.apots
              ? Object.values(WALLET_INFOS).map(wallet => (
                  <button
                    key={wallet.name}
                    className="flex w-full items-center gap-[20px] rounded-[12px] bg-gray px-[20px] py-[12px] text-left text-[18px] font-bold duration-300 hover:bg-purple [&:nth-child(n+2)]:mt-[16px]"
                    onClick={() => connectApt(wallet.name)}
                  >
                    <span className="flex w-[40px] justify-center">
                      <wallet.logo className="h-[30px]" />
                    </span>
                    <span>{wallet.name.toUpperCase()}</span>
                  </button>
                ))
              : [...configuredWallets].map(wallet => (
                  <button
                    key={wallet.name}
                    className="flex w-full items-center gap-[20px] rounded-[12px] bg-gray px-[20px] py-[12px] text-left text-[18px] font-bold duration-300 hover:bg-purple [&:nth-child(n+2)]:mt-[16px]"
                    onClick={async () => {
                      if (wallet.installed !== true) {
                        window.open(wallet.downloadUrl.browserExtension);
                        return;
                      }
                      try {
                        await select(wallet.name);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    <span className="flex w-[40px] justify-center">
                      <img src={wallet.iconUrl} className="h-[30px]" />
                    </span>
                    <span>{wallet.name.toUpperCase().split(' ')[0]}</span>
                  </button>
                ))}
          </div>
        ) : (
          <div
            className="w-[400px] bg-black p-[32px]"
            onClick={() =>
              chain === ChainName.apots
                ? disconnectApt(walletStates.connectedWallet as AptosWalletName)
                : disconnect()
            }
          >
            <button className="w-full rounded-[12px] bg-red py-[12px] text-[16px] font-semibold text-white">
              Disconnect
            </button>
          </div>
        )
      }
    >
      <div className={className}>
        <button
          className={`rounded-full bg-purple px-[16px] py-[8px] font-inter text-[15px] font-bold ${
            walletStates.address === '' ? '' : 'border-[2px] border-white bg-black'
          }`}
        >
          {walletStates.address === '' ? 'Connect Wallet' : hideAddress(walletStates.address)}
        </button>
      </div>
    </Popover>
  );
};

export default Wallet;
